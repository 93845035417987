import { useContext, useEffect, useRef } from 'react';
import { OptimizelyContext } from '@optimizely/react-sdk';
import * as _ from 'lodash';
import { Events } from '../../types/split.type';

interface UnitPanelProps {
    name: string;
    city: string;
    state: string;
    img: string;
}

export function UnitPanel(props: UnitPanelProps) {
    const { optimizely } = useContext(OptimizelyContext);
    const isEventSend = useRef<boolean>(false);
    const { name, city, state, img } = props;

    useEffect(() => {
        if (!_.isNil(optimizely) && !isEventSend.current) {
            optimizely.track(Events.REVIEWS_APP_SHOW_SUCCESS_EVENT);
            isEventSend.current = true;
        }
    }, [optimizely]);

    const renderUnitInfo = (): JSX.Element => {
        return (
            <div className={'base-font-body'}>
                <strong>{name}</strong>
                <div className={'unit-direction'}>
                    {city}, {state}
                </div>
            </div>
        );
    };
    return (
        <>
            <hr className={'hr-border'} />
            <div id={'unit-info-mobile'}>{renderUnitInfo()}</div>
            <div className={'reservation-details'}>
                <img src={img} alt={''} />
                <div id={'unit-info-desktop'}>{renderUnitInfo()}</div>
            </div>
            <hr className={'hr-border'} />
        </>
    );
}
