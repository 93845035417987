export class Configuration {
    public static getReviewsAPIUrl(): string {
        const { REACT_APP_REVIEWS_API_URL } = process.env;
        return REACT_APP_REVIEWS_API_URL ?? '';
    }

    public static getAppVersion(): string {
        const { REACT_APP_VERSION } = process.env;
        return REACT_APP_VERSION ?? '';
    }

    public static getProductName(): string {
        const { REACT_APP_PRODUCT_NAME } = process.env;
        return REACT_APP_PRODUCT_NAME ?? '';
    }

    public static getVacasaUrl(): string {
        const { REACT_APP_VACASA_URI } = process.env;
        return REACT_APP_VACASA_URI ?? '';
    }

    public static getTollFreePhoneNumber(): string {
        return '+1 800-544-0300';
    }

    public static getLocalPhoneNumber(): string {
        return '(503) 345-9399';
    }
}
