import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import { App } from './App';
import reportWebVitals from './reportWebVitals';
import { Configuration } from './Configuration';

const appVersion = Configuration.getAppVersion();
const appVersionContainer = document.getElementById('app-version');
const appProductName = Configuration.getProductName();
const appProductContainer = document.getElementById('app-product-name');
if (appVersionContainer) {
    appVersionContainer.innerHTML = appVersion;
}

if (appProductContainer) {
    appProductContainer.innerHTML = appProductName;
}
const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: webVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
