import React, { useEffect } from 'react';
import { MessagePanel } from '../../components';
import { ActionSegment, ErrorTypes } from '@reviews/interfaces';
import { useLocation, useSearchParams } from 'react-router-dom';
import { FrontendUtils } from '@reviews/frontend';

export const Home: React.FC = () => {
    const location = useLocation();
    const [searchParams] = useSearchParams();

    const reservationId = searchParams.get('reservation');
    const confirmationCode = searchParams.get('confirmation') ?? '';
    const nsuHash = searchParams.get('nsu') ?? '';
    const disposition = searchParams.get('disposition') ?? '';
    const starRating = searchParams.get('star-rating') ?? null;

    useEffect(() => {
        FrontendUtils.SharedUtils.setBaseItemForTracking(reservationId, confirmationCode, nsuHash, disposition, starRating);

        const footer = document.getElementById('footer');
        if (footer) {
            footer.classList.add('fix-bottom');
        }
    }, [confirmationCode, disposition, nsuHash, reservationId, starRating]);

    let kind = ErrorTypes.NOT_FOUND;

    if (location && location.state) {
        kind = (location.state as { kind: ErrorTypes }).kind;
    }

    return <MessagePanel kind={kind} action={ActionSegment.PAGE_LOAD} />;
};
