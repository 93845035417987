import { UserResponse } from './user.type';
import { JsonApiMetaData } from '@vacasa/json-api-common';

export interface GetBulkImports {
    data: Array<BulkImportResponse>;
    included?: Array<UserResponse>;
    meta?: JsonApiMetaData;
}

export interface GetS3AccessResponse {
    type: 'bulk-import';
    attributes: {
        link: string;
    };
}

export interface BulkImportResponse {
    type: 'bulk-import';
    id: number;
    attributes: BaseBulkImport;
}

/***********************************/

export interface BaseBulkImport {
    bulk_import_id: number;
    row_count: number;
    file: string;
    note: string;
    status: BulkImportStatus;
    created_by_id: number;
    created_at: Date;
    updated_at: Date;
}

export enum BulkImportStatus {
    BULK_IN_PROGRESS = 'IN_PROGRESS',
    BULK_COMPLETE = 'COMPLETED',
    BULK_ERROR = 'ERROR',
    BULK_UPLOAD_ERROR = 'UPLOAD_ERROR',
    BULK_SUPPRESSED = 'SUPPRESSED',
}
