export enum ErrorSegment {
    PRIOR_DATE = 'prior_date',
    EXPIRED = 'expired',
    ALREADY_EXISTS = 'already_exists',
    INVALID_PARAMS = 'invalid_params',
}

export enum ActionSegment {
    PAGE_LOAD = 'page_load',
    NEXT = 'next',
    SUBMIT = 'submit',
}
