import React, { useEffect, useRef } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { AppRoutes } from '../../Router';
import { Survey } from '../../components';
import { ActionSegment, SurveyType } from '@reviews/interfaces';
import segmentService from '../../services/segment/segmentService';
import _ from 'lodash';
import { Spinner } from '../../assests/icons';
import { getDisposition } from '../../utils/survey.util';
import { FrontendUtils } from '@reviews/frontend';

export const SurveyView: React.FC = () => {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();

    const tracked = useRef<boolean>(false);

    const reservationId = searchParams.get('reservation');
    const confirmationCode = searchParams.get('confirmation') ?? '';
    const nsuHash = searchParams.get('nsu') ?? '';
    const disposition = searchParams.get('disposition') ?? '';
    const starRating = searchParams.get('star-rating') ?? null;

    const requiredComment = true;
    const dispositionTemplate = getDisposition(starRating);

    useEffect(() => {
        FrontendUtils.SharedUtils.setBaseItemForTracking(reservationId, confirmationCode, nsuHash, disposition, starRating);
        if (!tracked.current) {
            const baseTrackingObj: { [key: string]: string | null } = {
                reservation_id: reservationId,
                confirmation_code: confirmationCode,
                nsu: nsuHash,
                disposition: disposition,
                star_rating: starRating,
            };
            segmentService.trackPage();
            segmentService.trackEvent({ action: ActionSegment.PAGE_LOAD, ...baseTrackingObj });
            //dataLayerService.trackEvent(featureFlagName, fv.variant);
            tracked.current = true;
        }
    }, [confirmationCode, disposition, nsuHash, reservationId, starRating]);

    useEffect(() => {
        if (!reservationId) {
            navigate(AppRoutes.HOME);
        }
    }, [navigate, reservationId]);

    if (!reservationId || _.isNil(requiredComment)) {
        return (
            <div className="loading">
                <Spinner />
            </div>
        );
    }

    const footer = document.getElementById('footer');
    if (footer) {
        footer.classList.remove('fix-bottom');
    }

    return (
        <Survey
            reservationId={+reservationId}
            confirmationCode={confirmationCode}
            nsuHash={nsuHash}
            type={SurveyType.REVIEW}
            surveyName={dispositionTemplate}
            commentRequired={requiredComment}
        />
    );
};
