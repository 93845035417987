import React, { useState, useEffect, useRef } from 'react';
import { IconCaretDownNoRadius, IconHeadSet } from '../../../assests/icons';
import { Configuration } from '../../../Configuration';

interface RightNavMenuProps {
    tollFreePhoneNumber: string;
    localPhoneNumber: string;
}

export const RightNavBarMenu: React.FC<RightNavMenuProps> = (props) => {
    const { tollFreePhoneNumber, localPhoneNumber } = props;
    const [isShow, setShow] = useState<boolean>(false);
    const vacasaUrl = Configuration.getVacasaUrl();

    const linkReferences = {
        help: useRef<any>(),
    };

    useEffect(() => {
        document.addEventListener('mousedown', (event) => {
            if (linkReferences.help.current && !linkReferences.help.current.contains(event.target)) {
                setShow(false);
            }
        });
    }, [linkReferences.help]);

    const renderDropdownLink = (id: string, displayName: string, displayValue: string, className: string = '') => {
        return (
            <a tabIndex={0} id={id} className={`dropdown-item ${className}`} href={`tel:${displayValue}`}>
                {`${displayName} ${displayValue}`}
            </a>
        );
    };

    return (
        <div className="navbar-nav ml-auto">
            <div tabIndex={0} className="nav-item dropdown d-flex mr-0" ref={linkReferences.help} onClick={() => setShow(!isShow)}>
                <a id="help-link" className="nav-link dropdown-toggle d-flex" data-toggle="dropdown">
                    <span>
                        <IconHeadSet className="mb-1 icon-head-set" />
                    </span>
                    <IconCaretDownNoRadius className="icon-caret-down-nav" />
                </a>
                <div className={`dropdown-menu dropdown-menu-left ${isShow && 'show'}`} aria-labelledby="help-link">
                    <a
                        tabIndex={0}
                        id="navbar-support"
                        target="_blank"
                        rel="noopener noreferrer"
                        href={`${vacasaUrl}/support`}
                        className="dropdown-item nav-font"
                    >
                        {'Support and Contact'}
                    </a>
                    {renderDropdownLink('navbar-tollfree-phone', 'Toll Free: ', tollFreePhoneNumber, 'nav-font')}
                    {renderDropdownLink('navbar-local-phone', 'Local: ', localPhoneNumber, 'nav-font')}
                </div>
            </div>
        </div>
    );
};
