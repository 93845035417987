export enum Splits {}

export enum Events {
    REVIEWS_APP_SHOW_SUCCESS_EVENT = 'reviews-app-show-success-event',
    REVIEWS_APP_SHOW_ERROR_EVENT = 'reviews-app-show-error-event',
    REVIEWS_APP_SUBMIT_EVENT = 'reviews-app-submit-event',
}

export enum Experiment {
    CONTROL = 'control',
}
