// GET#/v1/users
import { BulkImportResponse } from './bulk-import.type';
import { JsonApiMetaData } from '@vacasa/json-api-common';

export interface GetUsersResponse {
    data: Array<UserResponse>;
    included?: Array<BulkImportResponse>;
    meta?: JsonApiMetaData;
}

export interface UserResponse {
    type: 'user';
    id: number;
    attributes: BaseUser;
}

/************************/

export interface BaseUser {
    user_id: number;
    admin_user_id: number;
    name: string;
    email: string;
    role: Roles;
}

export type User = { user_id?: number; admin_user_id?: number; name: string; email: string; role?: string };

export enum Roles {
    ADMIN = 'ADMIN',
    EMPLOYEE = 'EMPLOYEE',
    AGENT = 'AGENT',
    TEAM_LEAD = 'TEAM_LEAD',
}
