import { StorageKeys } from '../types';

export const memoryStorage: { [key: string]: any } = {};
export class SharedUtils {
    public static setSessionStorageItem(key: string, item: any): void {
        try {
            sessionStorage.setItem(key, item);
            memoryStorage[key] = item;
        } catch (e) {
            console.error(e.message);
        }
    }

    public static getSessionStorageItem(key: string): string | null {
        return sessionStorage.getItem(key) || memoryStorage[key];
    }

    public static removeSessionStorageItem(key: string) {
        sessionStorage.removeItem(key);
        memoryStorage[key] = null;
    }

    public static clearSessionStorage() {
        sessionStorage.clear();
        for (const key of Object.keys(memoryStorage)) {
            memoryStorage[key] = null;
        }
    }

    public static setBaseItemForTracking(
        reservationId: string | null,
        confirmationCode: string,
        nsuHash: string,
        disposition: string,
        starRating: string | null,
        variant = 'control'
    ): void {
        this.setSessionStorageItem(StorageKeys.RESERVATION_ID, reservationId);
        this.setSessionStorageItem(StorageKeys.CONFIRMATION_CODE, confirmationCode);
        this.setSessionStorageItem(StorageKeys.NSU, nsuHash);
        this.setSessionStorageItem(StorageKeys.DISPOSITION, disposition);
        this.setSessionStorageItem(StorageKeys.STAR_RATING, starRating);
        this.setSessionStorageItem(StorageKeys.VARIANT, variant);
    }
}
