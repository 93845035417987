export interface ReviewsBulkResponse {
    type: 'reviews-bulk';
    attributes: ReviewsBulk;
}

/***********************************/

export interface ReviewsBulk {
    email: string;
    file: string;
    note: string;
}

export interface ReviewBulk {
    admin_unit_id: number;
    review_date: Date;
    guest_email: string;
    first_name: string;
    last_name: string;
    overall_rating: number;
    display_review: boolean;
    public_review_title: string;
    public_review: string;
}

export interface ReviewBulkWithUnit extends ReviewBulk {
    unit_id: number;
}

export interface ReviewsFileS3 {
    bulk_import_id: number;
    user_email: string;
    file_csv: string;
}

export enum HeaderBulkTypes {
    UNIT_ID = 'UnitID',
    REVIEW_DATE = 'ReviewDate',
    GUEST_EMAIL = 'GuestEmail',
    GUEST_FIRST_NAME = 'GuestFirstName',
    GUEST_LAST_NAME = 'GuestLastName',
    OVERALL_RATING = 'OverallRating',
    DISPLAY_REVIEW = 'DisplayReview',
    PUBLIC_REVIEW_TITLE = 'PublicReviewTitle',
    PUBLIC_REVIEW = 'PublicReview',
}

export type DataBulkTable = { [type in HeaderBulkTypes]: string | number | boolean | Date };
export type DataErrorBulk = { [key: string]: string | number | boolean | Date };
