import { BaseUser } from './user.type';
import { JsonApiBody } from './json-api.type';
import { MonolithReview, ReviewStatus } from './review.type';
import { FollowUpStatus, MaintenanceTicket } from './maintenance-ticket.type';
import { ReviewBulkWithUnit } from './reviews-bulk.type';

export interface CreateNoteResponse {
    type: 'create-history-note';
    attributes: SurveyHistory;
}

/*****************************/

export enum HistoryAction {
    CREATED = 'CREATED',
    PUBLISHED = 'PUBLISHED',
    SUPPRESSED = 'SUPPRESSED',
    NOTE = 'NOTE',
    ASSIGNED = 'ASSIGNED',
    EDITED = 'EDITED',
    RESPONSE_REQUIRED = 'RESPONSE_REQUIRED',
    REOPEN = 'REOPEN',
    FOLLOW_UP = 'FOLLOW_UP',
    TICKET_CREATED = 'TICKET_CREATED',
    ANSWERED = 'ANSWERED',
}

export type HistoryPayloadReviewStatus = {
    show_rating: boolean;
    show_review: boolean;
    show_rating_note: string;
    show_review_note: string;
    response: string;
    status: ReviewStatus;
};

export type HistoryPayloadUser = {
    user_id: number;
    email: string;
    name: string;
};
export type HistoryPayloadFollowUp = {
    status: FollowUpStatus;
};

export type HistoryPayloadData = HistoryPayloadFollowUp | HistoryPayloadReviewStatus | HistoryPayloadUser | MaintenanceTicket | string;
export type HistoryPayload = {
    field?: string;
    old?: HistoryPayloadData;
    new?: HistoryPayloadData;
    note?: string;
    source?: string;
    raw_review?: MonolithReview | ReviewBulkWithUnit | any;
};

export interface SurveyHistory {
    date: Date;
    data: HistoryPayload;
    created_by: BaseUser;
    action_type: HistoryAction;
}

export type SurveyHistoryResponse = JsonApiBody<'survey-response-history', SurveyHistory>;
