import { BaseUser, UserResponse } from './user.type';
import { JsonApiBody } from './json-api.type';
import { JsonApiMetaData } from '@vacasa/json-api-common';

export enum Severity {
    Urgent = 1,
    Today = 2,
    PriorToNextGuest = 3,
    Medium = 4,
    Low = 5,
    Planned = 6,
}

export enum Status {
    Unassigned = 0,
    Assigned = 1,
    Accepted = 2,
    Awaiting_Quote = 3,
    Resolution_Pending = 4,
    Resolved_Completed = 5,
    Resolved_Duplicate = 6,
    Unknown_7 = 7,
    Unknown_8 = 8,
    Resolved_Dismissed = 9,
}

export enum ResponseType {
    Response_needed = 'response_needed',
    Feedback_FYI = 'feedback_fyi',
}

export enum FollowUpStatus {
    Pending_Response = 'PENDING_RESPONSE',
    First_Update_Request = 'FIRST_UPDATE_REQUEST',
    Second_Update_Request = 'SECOND_UPDATE_REQUEST',
    Final_Update_Request = 'FINAL_UPDATE_REQUEST',
    Resolution = 'RESOLUTION',
}

export const FollowUpData: { [key: string]: { name: string; nextFollowUp: number; nextFollowUpStatus: string } } = {
    [FollowUpStatus.Pending_Response]: {
        name: 'Pending response',
        nextFollowUp: 3,
        nextFollowUpStatus: FollowUpStatus.First_Update_Request,
    },
    [FollowUpStatus.First_Update_Request]: {
        name: 'First follow up',
        nextFollowUp: 8,
        nextFollowUpStatus: FollowUpStatus.Second_Update_Request,
    },
    [FollowUpStatus.Second_Update_Request]: {
        name: 'Second follow up',
        nextFollowUp: 18,
        nextFollowUpStatus: FollowUpStatus.Final_Update_Request,
    },
    [FollowUpStatus.Final_Update_Request]: {
        name: 'Final update request',
        nextFollowUp: 28,
        nextFollowUpStatus: FollowUpStatus.Resolution,
    },
    [FollowUpStatus.Resolution]: {
        name: 'Resolution',
        nextFollowUp: 28,
        nextFollowUpStatus: '',
    },
};

export const mapTypeToString: { [key: string]: string } = {
    [ResponseType.Response_needed]: ' Response Needed',
    [ResponseType.Feedback_FYI]: ' Feedback FYI',
};

export const mapSeverityToString: { [key: number]: string } = {
    [Severity.Urgent]: 'Urgent',
    [Severity.Today]: 'Today',
    [Severity.PriorToNextGuest]: 'Prior to next guest',
    [Severity.Medium]: 'Medium',
    [Severity.Low]: 'Low',
    [Severity.Planned]: 'Planned',
};

export const mapStatusIdToString: { [key: number]: string } = {
    [Status.Unassigned]: 'Unassigned',
    [Status.Assigned]: 'Assigned',
    [Status.Accepted]: 'Accepted',
    [Status.Resolution_Pending]: 'Resolution Pending',
    [Status.Awaiting_Quote]: 'Awaiting Quote',
    [Status.Resolved_Completed]: 'Resolved Completed',
    [Status.Resolved_Duplicate]: 'Resolved Duplicate',
    [Status.Unknown_7]: 'Unknown 7',
    [Status.Unknown_8]: 'Unknown 8',
    [Status.Resolved_Dismissed]: 'Resolved',
};

export const GUEST_REVIEW_RESPONSE_DISPOSITION = 79;

export const REQUESTED_BY_EMPLOYEE = 3;

export interface MaintenanceTicket {
    maintenance_ticket_id: number;
    unit_id: number;
    admin_ticket_id: number;
    title: string;
    description?: string;
    status: number;
    severity: number;
    completion_date: Date;
    created_at: Date;
    updated_at: Date | undefined;
    assigned_to: number;
    created_by: BaseUser;
    created_by_id: number;
    disposition: number;
    response_type: string;
    survey_response_id: number;
    follow_up_status: string;
    follow_up_date: Date;
}

export type MaintenanceTicketResponse = JsonApiBody<'maintenance-ticket', MaintenanceTicket>;

export interface MaintenanceTicketDetailResponse {
    data: Array<MaintenanceTicketResponse>;
    included?: Array<UserResponse>;
    meta?: JsonApiMetaData;
}
