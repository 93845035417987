import { QuestionAttributeOptions, RatingRenderOptions } from '@reviews/interfaces';
import { Field } from 'formik';
import { useMemo } from 'react';
import { IconStarB } from '../../assests/icons';
import '../Survey/Survey.scss';
import './Rating.scss';
import _ from 'lodash';

type IndicatorConfiguration = {
    name: string;
    required: boolean;
    index: number;
    indicator: RatingRenderOptions.STAR | RatingRenderOptions.NUMBER | RatingRenderOptions.SMILEY;
    rating: number | undefined;
    placeholder?: string | null;
};

interface RatingIndicatorProps {
    configuration: IndicatorConfiguration;
    hoverRating: number | undefined;
    onMouseEnter: (index: number) => void;
    onMouseLeave: () => void;
    onSaveRating: (index: number) => void;
    range: number[];
}

const BACKGROUND_COLOR = '#FFD000';

export function RatingIndicator(props: RatingIndicatorProps): JSX.Element {
    const { configuration, hoverRating, onMouseEnter, onMouseLeave, onSaveRating, range } = props;

    const fill = useMemo((): 'none' | string => {
        if (configuration.indicator === RatingRenderOptions.NUMBER) {
            return configuration.index === configuration.rating ? BACKGROUND_COLOR : 'none';
        }

        const isHoveringAboveCurrent = Number(hoverRating) >= Number(configuration.index);
        const isBelowCurrentRating = Number(configuration.rating) >= Number(configuration.index);

        if (isHoveringAboveCurrent || isBelowCurrentRating) {
            return BACKGROUND_COLOR;
        }
        return 'none';
    }, [configuration.indicator, configuration.index, configuration.rating, hoverRating]);

    const handleClick = _.debounce(() => {
        onSaveRating(configuration.index);
    }, 5);

    const renderRatingOption = (configuration: IndicatorConfiguration): JSX.Element => {
        switch (String(configuration.indicator)) {
            case RatingRenderOptions.STAR:
                return (
                    <div>
                        <label onClick={handleClick}>
                            <Field
                                name={`${configuration.name}-${QuestionAttributeOptions.ANSWER.toLowerCase()}`}
                                id={`${configuration.name}-${QuestionAttributeOptions.ANSWER.toLowerCase()}`}
                                type={'radio'}
                                value={configuration.index}
                            />
                            <IconStarB fill={fill} />
                        </label>
                    </div>
                );
            case RatingRenderOptions.NUMBER: //NPS
                return (
                    <div className="rating-indicator">
                        <label
                            className={`btn btn-block ${fill != 'none' ? 'style-recommend-rating-yellow' : 'style-recommend-rating'}`}
                            onClick={() => onSaveRating(configuration.index)}
                        >
                            <Field
                                name={`${configuration.name}-${QuestionAttributeOptions.ANSWER.toLowerCase()}`}
                                id={`${configuration.name}-${QuestionAttributeOptions.ANSWER.toLowerCase()}`}
                                type={'radio'}
                                value={configuration.index}
                            />

                            {configuration.index}
                        </label>
                        {configuration.placeholder && range.includes(configuration.index) && (
                            <div
                                id={'indicator-placeholder'}
                                className={`indicator-placeholder ${range[1] == configuration.index ? 'last-indicator' : ''}`}
                            >
                                {configuration.placeholder}
                            </div>
                        )}
                    </div>
                );
            default:
                return <></>;
        }
    };

    return (
        <div className="rating-indicator" onMouseEnter={() => onMouseEnter(configuration.index)} onMouseLeave={() => onMouseLeave()}>
            {renderRatingOption(configuration)}
        </div>
    );
}
