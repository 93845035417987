import { AnswerPayload, Question, QuestionType } from './questions.type';

export interface SurveyResponseRequest {
    type: 'survey-response';
    attributes: SurveyResponse;
}

export interface UpdateSurveyResponseAnswerRequest {
    type: 'survey-response-answer';
    attributes: UpdateSurveyResponseAnswer;
}

export interface GetSurveyResponseAnswerResponse {
    type: 'survey-response-answer';
    id: number;
    attributes: SurveyResponseAnswers;
}

export interface SurveyQuestionsResponse {
    type: 'survey';
    attributes: SurveyDetails;
}
/****************************/

export enum SurveyType {
    REVIEW = 'REVIEW',
    REVIEW_GUEST_AIRBNB = 'REVIEW_GUEST_AIRBNB',
    REVIEW_HOST_AIRBNB = 'REVIEW_HOST_AIRBNB',
    REVIEW_GUEST_BOOKING = 'REVIEW_GUEST_BOOKING',
}

export enum SurveyName {
    REVIEW_SURVEY_OLD = 'REVIEW_SURVEY_OLD',
    REVIEW_SURVEY_NEW = 'REVIEW_SURVEY_NEW',
    REVIEW_SINGLE_PAGE = 'REVIEW_SINGLE_PAGE',
    REVIEW_NEGATIVE_DISPOSITION = 'REVIEW_NEGATIVE_DISPOSITION',
    REVIEW_POSITIVE_DISPOSITION = 'REVIEW_POSITIVE_DISPOSITION',
    REVIEW_PRIVATE_FEEDBACK_FIRST = 'REVIEW_PRIVATE_FEEDBACK_FIRST',
    REVIEW_GUEST_AIRBNB = 'REVIEW_GUEST_AIRBNB',
    REVIEW_HOST_AIRBNB = 'REVIEW_HOST_AIRBNB',
    REVIEW_BOOKING_AIRBNB = 'REVIEW_GUEST_BOOKING',
}

export enum ReviewSurveyId {
    SURVEY_GUEST_AIRBNB = 8,
    SURVEY_HOST_AIRBNB = 9,
    SURVEY_GUEST_BOOKING = 10,
}

export type SurveyDetails<T extends SurveyType = SurveyType.REVIEW> = {
    survey_id: string;
    name: string;
    description: string;
    sections: Section[];
    type: T;
} & (T extends SurveyType.REVIEW ? { context: ReviewSurveyContext } : {});

export interface UpdateSurveyResponseAnswer {
    new_value: string;
    old_value: string;
    survey_response_answers: SurveyResponseAnswers;
}

export interface SurveyTestInformation {
    feature_flags: Array<{
        name: string;
        variant: string;
    }>;
    star_rating: string;
}

export interface SurveyResponse {
    first_name: string;
    last_name: string;
    email: string;
    type: SurveyType;
    admin_reservation_id: number;
    admin_unit_id: number;
    source: string;
    survey_response_answers: Array<SurveyResponseAnswers>;
    survey_test_information?: SurveyTestInformation;
}

export type SurveyResponseAnswers = {
    survey_response_answers_id: number;
    survey_response_id: string;
    question_id: number;
    type: QuestionType;
    category: string;
    answer: AnswerPayload;
    created_at?: string;
    updated_at?: string;
};

export interface Section {
    section_id: number;
    title: string;
    description: string;
    section_questions: Array<Question>;
}

interface ReviewSurveyContext {
    unit: {
        admin_unit_id: number;
        name: string;
        state: string;
        country_code: string;
        city: string;
        img: string;
    };
    reservation: {
        admin_reservation_id: number;
        first_name: string;
        last_name: string;
        email: string;
    };
}

export interface GeneralInformation {
    survey_id: number;
    first_name: string;
    last_name: string;
    email: string;
    admin_reservation_id: number;
    admin_unit_id: number;
}

export enum SurveySources {
    GUEST = 'GUEST',
    BULK_IMPORT = 'BULK_IMPORT',
    MIGRATION = 'MIGRATION',
    CHANNELS = 'CHANNELS',
}
