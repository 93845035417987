export const MessageUtils: { [key: string]: string } = {
    title_review_expired: 'Looks like we just missed you',
    title_reservation_not_end: 'Looks like you are here ahead of time',
    title_reservation_already_answered: 'Hello again',
    title_page_under_maintenance: 'This page is under maintenance',
    title_thanks: 'Thanks for Sharing',
    title_invalid_params: 'Sorry, an error has ocurred',
    title_generic_error: 'This page is not available right now',

    button_lets_go_somewhere: 'Let’s Go Somewhere',

    description_review_expired: 'It’s too late to leave a review here—but we’d still love to hear from you! Let us know how we did at ',
    description_reservation_not_end: 'It’s too soon to leave a review, please come back after your checkout date.',
    description_reservation_already_answered: 'It looks like you’ve already submitted a review for this reservation thanks for doing that!',
    description_reservation_already_answered_note: 'Want to find your next getaway?',
    description_page_under_maintenance: 'We will be live again very soon. Thanks for you patience',
    description_thanks: 'We appreciate you taking the time to help us improve.',
    description_invalid_params:
        'Please check your email inbox for the most recent communication from Vacasa with an updated review link. If you continue to have issues, please contact ',
    description_generic_error: 'Please try later or call your Vacasa representative at ',
};
