export enum ErrorTypes {
    VALIDATION = 'VALIDATION',
    VALIDATION_PARAMETERS = 'VALIDATION_PARAMETERS',
    VALIDATION_FILTERS = 'VALIDATION_FILTERS',
    VALIDATION_BODY = 'VALIDATION_BODY',
    ASSIGNED = 'ASSIGNED',
    NOT_FOUND_ASSIGN = 'NOT_FOUND_ASSIGN',
    UNHANDLED = 'UNHANDLED',
    NOT_FOUND = 'NOT_FOUND',
    CONFLICT = 'CONFLICT',
    REVIEW_EXPIRED = 'REVIEW_EXPIRED',
    RESERVATION_NOT_END = 'RESERVATION_NOT_END',
    RESERVATION_ALREADY_ANSWERED = 'RESERVATION_ALREADY_ANSWERED',
    RESERVATION_IS_CANCELLED = 'RESERVATION_IS_CANCELLED',
    RESERVATION_NOT_FOUND = 'RESERVATION_NOT_FOUND',
    USER_NOT_FOUND = 'USER_NOT_FOUND',
    MAINTENANCE = 'MAINTENANCE',
}

export type ApiError = {
    title: string;
    type: ErrorTypes;
    status: number;
};

export interface DetailedError extends Error {
    app_version: string;
    message: string;
    status: number;
    code?: string | number;
}

export const Errors: { [type in ErrorTypes]: ApiError } = {
    [ErrorTypes.VALIDATION]: {
        title: 'Validation error in request',
        type: ErrorTypes.VALIDATION,
        status: 400,
    },
    [ErrorTypes.ASSIGNED]: {
        title: 'Validation error in request',
        type: ErrorTypes.ASSIGNED,
        status: 400,
    },
    [ErrorTypes.VALIDATION_PARAMETERS]: {
        title: 'Params is not valid',
        type: ErrorTypes.VALIDATION_PARAMETERS,
        status: 400,
    },
    [ErrorTypes.USER_NOT_FOUND]: {
        title: 'user does not exist',
        type: ErrorTypes.USER_NOT_FOUND,
        status: 403,
    },
    [ErrorTypes.VALIDATION_FILTERS]: {
        title: 'Filters is not valid',
        type: ErrorTypes.VALIDATION_FILTERS,
        status: 400,
    },
    [ErrorTypes.VALIDATION_BODY]: {
        title: 'Body is null or undefined',
        type: ErrorTypes.VALIDATION_BODY,
        status: 400,
    },
    [ErrorTypes.NOT_FOUND]: {
        title: 'Not found',
        type: ErrorTypes.NOT_FOUND,
        status: 404,
    },
    [ErrorTypes.NOT_FOUND_ASSIGN]: {
        title: 'Not found',
        type: ErrorTypes.NOT_FOUND_ASSIGN,
        status: 404,
    },
    [ErrorTypes.CONFLICT]: {
        title: 'Conflict',
        type: ErrorTypes.CONFLICT,
        status: 409,
    },
    [ErrorTypes.UNHANDLED]: {
        title: 'Unhandled error occurred',
        type: ErrorTypes.UNHANDLED,
        status: 500,
    },
    [ErrorTypes.RESERVATION_NOT_END]: {
        title: 'Reservation is not finished',
        type: ErrorTypes.RESERVATION_NOT_END,
        status: 400,
    },
    [ErrorTypes.REVIEW_EXPIRED]: {
        title: 'Reservation review period has expired',
        type: ErrorTypes.REVIEW_EXPIRED,
        status: 400,
    },
    [ErrorTypes.RESERVATION_ALREADY_ANSWERED]: {
        title: 'Reservation already has the survey response',
        type: ErrorTypes.RESERVATION_ALREADY_ANSWERED,
        status: 400,
    },
    [ErrorTypes.RESERVATION_IS_CANCELLED]: {
        title: 'Reservation is cancelled',
        type: ErrorTypes.RESERVATION_IS_CANCELLED,
        status: 400,
    },
    [ErrorTypes.RESERVATION_NOT_FOUND]: {
        title: 'Reservation not found',
        type: ErrorTypes.RESERVATION_NOT_FOUND,
        status: 404,
    },
    [ErrorTypes.MAINTENANCE]: {
        title: 'Site is under maintenance',
        type: ErrorTypes.MAINTENANCE,
        status: 503,
    },
};
