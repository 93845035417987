export interface PromptResponse {
    type: 'prompt';
    id: number;
    attributes: BasePromptResponse;
}

/*******************************/

export interface BasePromptResponse {
    prompt_id: number;
    content: object;
    test: boolean;
    description: string;
    need_input_examples?: Array<boolean>;
}

export interface PromptContentObject {
    messages: [{ role: string; content: string }];
    temperature?: number;
    model?: string;
}

export enum FormatReviewContent {
    JSON = 'json',
    STRING = 'string',
}

export type InvalidContent = {
    [key: string]: any;
};

export const baseFormatAnswerPromptString = [
    {
        role: 'user',
        content:
            "You must response in JSON format with the following keys:\n- score: confidence score from 0 to 10, which means you don't feel ok publishing the review, all the way to 10 if you feel absolutely confident that the review can be published. If anything doesn't match the criteria it should significantly affect the confidence score.\n- publish: true or false. Only true if score is equal to 10\n- reason: why the review can or can't be public.\n- summary: provides a concise summary of the key points and overall sentiment expressed in the review.\n- issues: field describes the specific rule violations found in the review. It provides a summary of the violations that have been identified.\n- actions: list of identified issues and their required actions to improve feature guest experiences.\nDo you understand the role?",
    },
    {
        role: 'assistant',
        content:
            'Yes, I understand the role and the required JSON format for the response. I will provide the evaluation of the review in the specified format with the keys: score, publish, reason, issues, and actions. Please provide me with the review you would like me to evaluate, and I will generate the response accordingly.',
    },
    {
        role: 'user',
        content:
            'Review: Beautiful LOVELY condos to stay in #415 It was very clean, beautiful, lovely and very roomie. We enjoyed our stay at room 415 and plan on coming back soon to hopefully 415#... We loved it! Thank you. William and Jennifer Smith \n It was perfect !',
    },
    {
        role: 'assistant',
        content:
            '{"score": 2,"publish": false,"reason": "The review contains personal identifiable information (names) that should be redacted.","summary":"Positive feedback about the stay in room 415, overall positive experience.","issues": "Personal names mentioned","actions": ["Redact the personal names William and Jennifer Smith from the review before publishing."]}',
    },
    {
        role: 'user',
        content:
            "Review: Perfect Home This home was absolutely perfect for my family. Had absolutely everything we needed for the stay, only negative comment I would have is the twin beds are kind of hard. The others are like laying on a big pillow. One of the best beaches we've ever visited. Private and lots and lots of shells. Will definitely be back in the near future.",
    },
    {
        role: 'assistant',
        content:
            '{"score": 3,"publish": true,"reason": "The review meets all the publication criteria except for a minor complaint about the twin beds being hard.","issues": "Mention of hard twin beds","actions": ["Consider providing additional mattress toppers or options for guests who prefer softer beds for the twin beds to improve guest comfort."]}',
    },
    {
        role: 'user',
        content:
            'Review: Nice end of the year getaway This house is beautiful. They had everything we needed and more. Lots of pots and pans, baking items, cleaning supplies, and more. We had enough space for everyone and space for intimate settings too!',
    },
    {
        role: 'assistant',
        content:
            '{"score": 1,"publish": false,"reason": "The review mentions \'intimate settings,\' which could be interpreted as a violation of privacy concerns.","issues":"Mention of intimate settings","actions":["Consider rephrasing the sentence to focus on the spaciousness and versatility of the property without mentioning \'intimate settings\' to ensure guest privacy is maintained."]}',
    },
    {
        role: 'user',
        content:
            "Review: More about the house Previously I've written about the house a lot.  So thought I'd say a bit about the neighborhood.  OM House is in Deepwell, a mid century corner of south PS frequented by the Hollywood folk during the later years of the big name studios.   On the same cul de sac as OM House, are the homes of Julie and Gavin.   Around the corner is Loretta's circular house where she lived full time.  William's 4 lot house and grounds are also closeby.    Cactus Rd these days has two rental houses.  The rest are owned by permanent residents.   So, it's quiet and quiet is appreciated by the locals.  \\nDeepwell is a great walking and bike riding area.  It's flat, little traffic and the array of mid century houses will astound you.  It's a unique place.  I predict you'll love it. ",
    },
    {
        role: 'assistant',
        content:
            '{"score": 2,"publish": false,"reason": "The review contains personal identifiable information (names) that should be redacted and mentions specific addresses and details that resemble address information.","issues": "Personal names mentioned, address details mentioned","actions": ["Redact the personal names \'Julie,\' \'Gavin,\' \'Loretta,\' and \'William\' from the review before publishing.","Remove specific address details and references to specific houses to protect privacy."]}',
    },
    {
        role: 'user',
        content:
            "Review: Absolutely filthy! The place was a disaster. Dirt, stains, and hair everywhere \n The property was a complete nightmare. Nothing worked, and it was falling apart \n The staff was so rude and unprofessional. They didn't care about our concerns at all.\n The Wi-Fi was terrible. It was slow and unreliable, making it impossible to get any work done \n Worst experience ever! I stayed at 789 Oak Street, Anytown, USA, and it was a disaster. The owner, was an absolute scam artist. The place was infested with bugs, the appliances were broken, and the neighborhood was unsafe. The rental management company, ABC Rentals, ignored our complaints and refused to provide any refunds. Avoid this place like the plague!",
    },
    {
        role: 'assistant',
        content:
            '{"score": 0,"publish": false,"reason": "Review does not meet the criteria for publication.","issues": "Inappropriate language in the cleanliness category. Explicit negative points and complaints in the property category. Complaints directed towards staff in the staff category. Negative feedback about Wi-Fi in the wifi category. Explicit negative points, issues, and complaints in the overall category.","actions": ["Redact inappropriate language in the cleanliness category.","Address the explicit negative points and complaints in the property category.","Handle the complaints directed towards staff in the staff category.","Improve Wi-Fi service to address the negative feedback in the wifi category.","Address the explicit negative points, issues, and complaints in the overall category."]}',
    },
];

export const baseFormatAnswerPromptObject = [
    {
        role: 'user',
        content:
            "I Provide each review in a JSON structured format that includes the following information for each attribute: Category name (overall, cleanliness, property, staff, wifi, or general) the key and Comment (the guest's comments about of category) that is a value.",
    },
    {
        role: 'user',
        content:
            "You must response in JSON format with the following keys:\n- score: confidence score from 0 to 10, which means you don't feel ok publishing the review, all the way to 10 if you feel absolutely confident that the review can be published. If anything doesn't match the criteria it should significantly affect the confidence score.\n- publish: true or false. Only true if score is equal to 10\n- reason: why the review can or can't be public.\n- summary: provides a concise summary of the key points and overall sentiment expressed in the review.\n- issues: field describes the specific rule violations found in the review. It provides a summary of the violations that have been identified.\n- actions: list of identified issues and their required actions to improve feature guest experiences.\nDo you understand the role?",
    },
    {
        role: 'user',
        content:
            'Please, it is very important that you respond only with json format. The entire response must be in a JSON, because only in this way can the response be processed.DO NOT add notes or any additional text to the JSON',
    },

    {
        role: 'user',
        content:
            '{"overall": "Beautiful LOVELY condos to stay in #415 It was very clean, beautiful, lovely and very roomie. We enjoyed our stay at room 415 and plan on coming back soon to hopefully 415#... We loved it! Thank you. Jessica and Jeremy Conley ","general": "It was perfect !"}',
    },
    {
        role: 'assistant',
        content:
            '{"score": 0.2,"publish": false,"reason": "The review contains personal identifiable information (names) that should be redacted.","summary":"Positive feedback about the stay in room 415, overall positive experience.","issues": "Personal names mentioned","actions": ["Redact the personal names Jessica and Jeremy Conley from the review before publishing."]}',
    },
];

export const baseFormatAnswerPromptObjectV2 = [
    {
        role: 'user',
        content:
            "I Provide each review in a JSON structured format that includes the following information for each attribute: Category name (overall, cleanliness, property, staff, wifi, or general) the key and Comment (the guest's comments about of category) that is a value.",
    },
    {
        role: 'user',
        content:
            "You must response in JSON format with the following keys:\n- score: confidence decimal score from 0, which means you don't feel ok publishing the review, all the way to 1 if you feel absolutely confident that the review can be published. If anything doesn't match the criteria it should significantly affect the confidence score.\n- publish: true or false. Only true if score is equal to 1\n- reason: why the review can or can't be public.\n- summary: provides a concise summary of the key points and overall sentiment expressed in the review.\n- issues: field describes the specific rule violations found in the review. It provides a summary of the violations that have been identified.\n- actions: list of identified issues and their required actions to improve feature guest experiences.\nDo you understand the role?",
    },
    {
        role: 'user',
        content:
            'Please, it is very important that you respond only with json format. The entire response must be in a JSON, because only in this way can the response be processed.DO NOT add notes or any additional text to the JSON',
    },
    {
        role: 'assistant',
        content:
            'Yes, I understand the role and the required JSON format for the response. I will provide the evaluation of the review in the specified format with the keys: score, publish, reason, issues, and actions. Please provide me with the review you would like me to evaluate, and I will generate the response accordingly.',
    },
    {
        role: 'user',
        content:
            '{"overall": "Beautiful LOVELY condos to stay in #415 It was very clean, beautiful, lovely and very roomie. We enjoyed our stay at room 415 and plan on coming back soon to hopefully 415#... We loved it! Thank you. Jessica and Jeremy Conley ","general": "It was perfect !"}',
    },
    {
        role: 'assistant',
        content:
            '{"score": 2,"publish": false,"reason": "The review contains personal identifiable information (names) that should be redacted.","issues": "Personal names mentioned","actions": ["Redact the personal names Jessica and Jeremy Conley from the review before publishing."]}',
    },
];

export const baseFormatAnswerPromptForSummary = [
    {
        role: 'user',
        content:
            "I have a review from a guest that stayed at my home and I'll like a summary, starting with the general review sentiment and then highlighting the good and bad as brief narrative. It should be a concise paragraph, ensuring the summary is shorter than the original review, and produce the following output:\n" +
            '\n' +
            'Summary:\n' +
            'Here goes the overall sentiment , being that positive, negative or mixed. Here goes the goods and bads\n' +
            '\n' +
            'Can you help me?',
    },
    {
        role: 'assistant',
        content:
            "Of course, I'd be happy to help you with that. Please provide me with the guest's review, and I'll generate a concise summary for you as per your request.",
    },
];

export const promptForIdentifiedIssues = [
    [
        {
            role: 'user',
            content:
                "Hello, I  have received a review from a guest who recently stayed at my home. Could you please carefully analyze the feedback? Look for any issues, concerns, or areas that the guest felt were lacking, and also identify if there are any suggestions, wishes, or recommendations for improvement or updates that the guest has mentioned. Please provide a well-organized summary that highlights and categorizes the identified issues and recommendations, ensuring that all key aspects of the guest's feedback are captured and clearly presented.\n" +
                '\n' +
                'produce output with the following format:\n' +
                'Response:\n' +
                "'a bit text with summary of issues found\n" +
                '\n' +
                'if review does not have issues only return "None"' +
                'Can you help me?',
        },
        {
            role: 'assistant',
            content:
                "Of course, I'd be happy to help you with that. Please provide the guest's review, and I'll be happy to analyze it for you.",
        },
        {
            role: 'user',
            content:
                'A will equipped apartment' +
                '\n' +
                'The kitchen has many useful items for cooking. The pool was lovely and the complex sighted a short walk from the restaurants etc in the village .' +
                '\n' +
                'Comfortable bed and chairs. The carpet is unfortunately stained in places',
        },
        {
            role: 'assistant',
            content:
                'Response: The guest mentioned that the carpet in the apartment is unfortunately stained in places. This indicates a cleanliness issue that needs to be addressed',
        },
    ],
    [
        {
            role: 'user',
            content:
                'Please analyze the provided guest review of my property meticulously. Identify and extract any suggestions or mentions of problems faced by the guests during their stay, even if they are expressed subtly or the overall review is positive. Also, please look for mentions of external factors or conditions not directly related to the property but could be beneficial as "notes" for future guests or improvements.\n' +
                '\n' +
                'Ensure that your analysis is comprehensive, capturing all areas that have room for enhancement or necessitate attention. Finally, compile a concise and organized summary, categorizing the identified suggestions, problems, and notable external factors for a clear and actionable overview.\n' +
                '\n' +
                'produce output with the following format:\n' +
                'Response:\n' +
                "'a bit text with summary of suggestions found\n" +
                '\n' +
                'if review does not have suggestions only return "None"' +
                'Can you help me?',
        },
        {
            role: 'assistant',
            content:
                "Of course, I'd be happy to help you with that. Please provide the guest's review, and I'll be happy to analyze it for you.",
        },
        {
            role: 'user',
            content:
                'Nice, well located place albeit right on a rather busy street.' +
                '\n' +
                'Comfortable overall but could use more comfortable furniture-couch was good. Bedrooms could use seating.' +
                '\n' +
                'Nice, well located place overall although wish the pool was warmer. Good older furniture, well furnished. Great beds.',
        },
        {
            role: 'assistant',
            content:
                'Response: Overall, the suggestions mentioned by the guest include adjusting the pool temperature, improving the comfort of the furniture, and adding seating options in the bedrooms.',
        },
    ],
    [
        {
            role: 'user',
            content:
                "Please evaluate the guest review carefully, focusing on any issues mentioned or implied, and consider potential improvements, updates, or changes suggested by the guest. Ensure to capture even minor details or subtle hints that indicate the guest’s preferences or areas they felt could be enhanced. Synthesize these insights into a brief, clear summary, outlining actionable suggestions for improving the guest experience based on the guest's feedback." +
                '\n' +
                'produce output with the following format:\n' +
                'Response:\n' +
                "'a bit text with summary of suggestions found\n" +
                '\n' +
                'if review does not have suggestions only return "None"' +
                'Can you help me?',
        },
        {
            role: 'assistant',
            content:
                "Of course, I'd be happy to help you with that. Please provide the guest's review, and I'll be happy to analyze it for you.",
        },
        {
            role: 'user',
            content:
                'Nice, well located place albeit right on a rather busy street.' +
                '\n' +
                'Comfortable overall but could use more comfortable furniture-couch was good. Bedrooms could use seating.' +
                '\n' +
                'Nice, well located place overall although wish the pool was warmer. Good older furniture, well furnished. Great beds.',
        },
        {
            role: 'assistant',
            content:
                'Response: Overall, the suggestions mentioned by the guest include adjusting the pool temperature, improving the comfort of the furniture, and adding seating options in the bedrooms.',
        },
    ],
];
