import { customAlphabet } from 'nanoid';
const nanoid = customAlphabet('ABCDEFGHJKLMNPQRSTUVWXYZ1234567890', 8);
export const flowId = nanoid();

class DDLogger {
    private logger = (window as any).DD_LOGS ? (window as any).DD_LOGS.logger : null;

    public info(message: string, data?: { [key: string]: any }) {
        if (this.logger) {
            this.logger.info(message, { ...data, flowId });
        } else {
            console.log(message);
        }
    }

    public error(message: string, data?: { [key: string]: any }) {
        if (this.logger) {
            this.logger.error(message, { ...data, flowId });
        } else {
            console.error(message);
        }
    }
}

const defaultLogger = new DDLogger();
export default defaultLogger;
