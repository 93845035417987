export interface Question {
    question_id: number;
    question_order: number;
    name: string;
    title: string | null;
    description: string | null;
    required: boolean;
    type: QuestionType;
    configuration: BaseOptions & (RatingOptions | CommentOptions);
}

type QuestionAnswer = {
    answer?: number | string;
    comment?: string | null;
    title?: string | null;
};

export type AnswerPayload = {
    dismissed: boolean;
    data: QuestionAnswer | null;
};

export type BaseOptions = {
    dismissible: boolean;
    dismissible_message?: string | null;
    allow_comment: boolean;
    comment?: Comment | null;
};

export type CommentOptions = {
    type: QuestionType.COMMENT;
    options: {
        placeholder: string;
        max_length: number;
        title?: {
            required: true;
            options: {
                placeholder: string;
                max_length: number;
            };
        };
    };
};

export type RatingOptions = {
    type: QuestionType.RATING;
    options: {
        render: RatingRenderOptions.STAR | RatingRenderOptions.NUMBER | RatingRenderOptions.SMILEY;
        min: number;
        max: number;
        allow_placeholder: boolean;
        placeholders_by_rating: {
            [key: string]: string | null;
        } | null;
    };
};

type Comment = {
    type: 'TEXT';
    require: boolean;
    options: {
        placeholder: string;
        max_length: number;
    };
};

export enum RatingRenderOptions {
    STAR = 'STAR',
    NUMBER = 'NUMBER',
    SMILEY = 'SMILEY',
}

export enum QuestionType {
    RATING = 'RATING',
    COMMENT = 'COMMENT',
    CHECKBOX = 'CHECKBOX',
}
export enum QuestionCategory {
    OVERALL = 'OVERALL',
    PUBLIC_REVIEW = 'PUBLIC_REVIEW',
    CLEANLINESS = 'CLEANLINESS',
    PROPERTY = 'PROPERTY',
    STAFF = 'STAFF',
    WIFI = 'WIFI',
    LOCATION = 'LOCATION',
    NPS = 'NPS',
    GENERAL_FEEDBACK = 'GENERAL_FEEDBACK',
}

export enum QuestionAttributeOptions {
    ANSWER = 'ANSWER',
    COMMENT = 'COMMENT',
    TITLE = 'TITLE',
    DISMISSED = 'DISMISSED',
}

export const MapQuestionCategoryToString: { [key: string]: string } = {
    [QuestionCategory.NPS]: 'NPS',
    [QuestionCategory.CLEANLINESS]: 'Cleanliness',
    [QuestionCategory.WIFI]: 'WIFI',
    [QuestionCategory.LOCATION]: 'Location',
    [QuestionCategory.OVERALL]: 'Overall',
    [QuestionCategory.GENERAL_FEEDBACK]: 'General',
    [QuestionCategory.PUBLIC_REVIEW]: 'Public Review',
    [QuestionCategory.PROPERTY]: 'Property',
    [QuestionCategory.STAFF]: 'Staff',
};
