import React, { createContext, ReactNode, useContext, useEffect, useState } from 'react';
import { ThemeProvider } from '@vacasa/react-components-lib';
import defaultTheme from '@vacasa/react-components-lib/lib/themes/default';
import { Router } from './Router';
import { Provider } from 'react-redux';
import { OptimizelyProvider } from '@optimizely/react-sdk';
import { store } from './store/store';
import { Col, Row } from 'react-bootstrap';
import { NavBar } from './components/NavBar/NavBar/NavBar';
import { Footer } from './components/Footer/Footer';
import Container from '@mui/material/Container';
import OptimizelyService from './services/optimizelyService';
import { Spinner } from './assests/icons';
import { useSegmentLoader } from './hooks';
import SegmentService from './services/segment/segmentService';
import _ from 'lodash';
import { StorageKeys } from '@reviews/frontend';

const UserIdContext = createContext<string | null>(null);

export const useUserId = (): string | null => useContext(UserIdContext);

interface UserIdProviderProps {
    children: ReactNode;
}

const UserIdProvider: React.FC<UserIdProviderProps> = ({ children }) => {
    const [segmentLoaded, setSegmentLoaded] = useState<boolean>(false);
    const [anonymousId, setAnonymousId] = useState<null | string>(null);
    const [timeoutExceeded, setTimeoutExceeded] = useState<boolean>(false);

    useSegmentLoader();

    useEffect(() => {
        const onSegmentReady = () => {
            setSegmentLoaded(true);
            const anonymousId = window.analytics.user().anonymousId();
            setAnonymousId(anonymousId);
            localStorage.setItem(StorageKeys.REVIEWS_ANONYMOUS_ID, anonymousId);
        };

        if (SegmentService.userAnalyticExist()) {
            onSegmentReady();
        } else {
            window.analytics?.ready(onSegmentReady);
            // Set up a timeout in case Segment does not load
            const timer = setTimeout(() => {
                setTimeoutExceeded(true); // Allow the application to continue
            }, 5000);

            return () => clearTimeout(timer); // Clear the timer if the component unmounts
        }
    }, []);

    if (_.isNil(anonymousId) && !segmentLoaded && !timeoutExceeded) {
        return (
            <div className="loading">
                <Spinner />
            </div>
        );
    }

    return <UserIdContext.Provider value={anonymousId}>{children}</UserIdContext.Provider>;
};

const InternalApp = () => {
    const userId = useUserId();
    const optimizely = OptimizelyService.getOptimizelyInstance();

    return (
        <>
            <OptimizelyProvider optimizely={optimizely as any} user={{ id: userId }}>
                <Container maxWidth={false} className="app-container">
                    <Provider store={store}>
                        <ThemeProvider theme={defaultTheme}>
                            <Row>
                                <NavBar />
                            </Row>
                            <Row>
                                <Col id="main-content" xs={12} sm={12} md={12}>
                                    <Router />
                                </Col>
                            </Row>
                            <Footer />
                        </ThemeProvider>
                    </Provider>
                </Container>
            </OptimizelyProvider>
        </>
    );
};

export function App() {
    return (
        <UserIdProvider>
            <InternalApp />
        </UserIdProvider>
    );
}
