import { BaseReviewResponse } from './review.type';
import { BasePromptResponse, FormatReviewContent } from './prompt.type';

export interface ReviewAutoProcessResponse {
    type: 'review-auto-process';
    id: number;
    attributes: BaseReviewAutoProcessResponse;
}

/*******************************/

export interface BaseReviewAutoProcessResponse {
    review_auto_process_id: number;
    ai_response: object;
    ai_process: boolean;
    auto_published: boolean;
    issues_identified: string;
    review: BaseReviewResponse | any;
    prompt: BasePromptResponse | any;
}

export interface AutoProcessReviewEvent {
    review_id: number;
    is_test_flow: boolean;
    total_executions: number;
    content_format: FormatReviewContent;
    current_execution: number;
    prompt_id: number;
    prompt: BasePromptResponse;
}

export interface ReviewAutoProcessSummary {
    general_summary: string;

    issues_summary: string;

    issues_pii: { pii_type: string; identified_words: string[] }[];
}
export const DEFAULT_GPT_MODEL = 'gpt-3.5-turbo';

export const DEFAULT_GPT_TEMP = 0;
