import { BaseOptions, CategoryName, QuestionAttributeOptions, RatingOptions } from '@reviews/interfaces';
import { useState } from 'react';
import { Field } from 'formik';
import { RatingIndicator } from './RatingIndicator';
import '../Survey/Survey.scss';
import _ from 'lodash';
import segmentService from '../../services/segment/segmentService';

interface RatingProps {
    name: string;
    title: string | null;
    description: string | null;
    required: boolean;
    configuration: BaseOptions & RatingOptions;
}

export function Rating(props: RatingProps): JSX.Element {
    const { name, title, description, required, configuration } = props;
    const { allow_comment: allowComment, dismissible } = configuration;

    const category = name.split('-').slice(1, 2).pop();

    const [rating, setRating] = useState<number | undefined>();
    const [hoverRating, setHoverRating] = useState<number | undefined>();
    const [isShowingComment, setIsShowingComment] = useState(false);
    const [isDismissed, setIsDismissed] = useState(false);

    const onMouseEnter = (index: number): void => {
        setHoverRating(index);
    };
    const onMouseLeave = (): void => {
        setHoverRating(0);
    };
    const onSaveRating = (index: number): void => {
        setRating(index);
        segmentService.trackEvent(
            {
                category: _.toLower(category),
                value: index,
            },
            'Guest Review Rating Selected'
        );
    };
    const handleToggleAddCommentTextArea = (): void => {
        setIsShowingComment(!isShowingComment);
        setIsDismissed(false);
    };

    const handleToggleDismiss = () => {
        setIsDismissed(!isDismissed);
        setRating(undefined);
        setIsShowingComment(false);
    };

    const getValuesInRange = (min: number, max: number): Array<number> => {
        let rows = [];
        for (let i = min; i <= max; i++) rows.push(i);
        return rows;
    };

    return (
        <div className={'question base-font-body no-border-bottom'}>
            <div className="container">
                <div className="category-name">{title}</div>
                {dismissible && (
                    <div id={'checkbox-desktop'} className="caption checkbox">
                        <Field
                            name={`${name}-${QuestionAttributeOptions.DISMISSED}`}
                            type="checkbox"
                            checked={isDismissed}
                            id={`${name}-${QuestionAttributeOptions.DISMISSED}`}
                            onClick={handleToggleDismiss}
                            color="primary"
                        />{' '}
                        <label className={'category-description'} htmlFor={`${name}-${QuestionAttributeOptions.DISMISSED}`}>
                            {configuration.dismissible_message}
                        </label>
                    </div>
                )}
            </div>
            <div className={`caption category-description ${name.toLowerCase()}`}>{description}</div>
            {_.isEqual(_.toString(category), CategoryName.NPS) && (
                <div id={'box-indicator-text'}>
                    <span className={'left'}>0 -Not likely</span>
                    <span className={'right'}>10 -Very likely</span>
                </div>
            )}
            <div className={`${configuration.options.render === 'STAR' ? 'star-rating' : 'recommend-rating-wrapper'}`}>
                {!isDismissed && (
                    <div className={`${configuration.options.render === 'STAR' ? 'star-rating' : 'new-recommend-rating'}`}>
                        {getValuesInRange(+configuration.options.min, +configuration.options.max).map((index) => {
                            return (
                                <RatingIndicator
                                    key={index}
                                    range={[configuration.options.min, configuration.options.max]}
                                    configuration={{
                                        name: name,
                                        required: required,
                                        index: index,
                                        indicator: configuration.options.render,
                                        rating: rating,
                                        placeholder: (configuration.options.placeholders_by_rating || {})[index],
                                    }}
                                    hoverRating={hoverRating}
                                    onMouseEnter={onMouseEnter}
                                    onMouseLeave={onMouseLeave}
                                    onSaveRating={onSaveRating}
                                />
                            );
                        })}
                    </div>
                )}
            </div>
            {allowComment && (
                <div className={'field-wrapper placeholder-font'}>
                    {isShowingComment ? (
                        <>
                            <br />
                            <Field
                                name={`${name}-${QuestionAttributeOptions.COMMENT.toLowerCase()}`}
                                id={`${name}-${QuestionAttributeOptions.COMMENT.toLowerCase()}`}
                                as="textarea"
                                className={'form-control service-comment text-area-border'}
                                spellCheck="false"
                                maxLength={configuration.comment && configuration.comment.options.max_length}
                                placeholder={(configuration.comment && configuration.options.placeholders_by_rating![rating ?? 5]) || ''}
                            />
                            <br />
                            <div className={'feedback-comment'} role={'button'} onClick={handleToggleAddCommentTextArea}>
                                {'x CLOSE'}
                            </div>
                        </>
                    ) : (
                        <>
                            <br />
                            <div className={'feedback-comment'} role={'button'} onClick={handleToggleAddCommentTextArea}>
                                {'+ ADD COMMENT'}
                            </div>
                        </>
                    )}
                </div>
            )}
            {dismissible && (
                <div id={'checkbox-mobile'} className="caption checkbox">
                    <Field
                        name={`${name}-${QuestionAttributeOptions.DISMISSED}`}
                        type="checkbox"
                        checked={isDismissed}
                        id={`${name}-${QuestionAttributeOptions.DISMISSED}`}
                        onClick={handleToggleDismiss}
                        color="primary"
                    />{' '}
                    <label className={'category-description'} htmlFor={`${name}-${QuestionAttributeOptions.DISMISSED}`}>
                        {configuration.dismissible_message}
                    </label>
                </div>
            )}
        </div>
    );
}
