import React from 'react';
import { Configuration } from '../../../Configuration';

interface LeftMobileNavProps {
    tollFreePhoneNumber: string;
    localPhoneNumber: string;
}

export const LeftMobileNavMenu: React.FC<LeftMobileNavProps> = (props) => {
    const { tollFreePhoneNumber, localPhoneNumber } = props;
    const vacasaUrl = Configuration.getVacasaUrl();

    return (
        <div id="sidenavbar-left" className="d-lg-none d-none sidenavbar left-inactive fixed-top">
            <div className="sidenavbar-content flex-column pt-3">
                <div className="left-panel-margin nav-font-mobile">
                    <div className="mx-3">{'Help'}</div>
                    <div className="mx-32">
                        <a
                            target="_blank"
                            rel="noopener noreferrer"
                            href={`${vacasaUrl}/support`}
                            id="navbar-support-mobile"
                            className="sidenavbar-item"
                        >
                            <span>{'Support and Contact'}</span>
                        </a>
                        <a id="navbar-tollfree-phone-mobile" className="sidenavbar-item" href={`tel:+18005440300`}>
                            <span>{`Toll Free: ${tollFreePhoneNumber}`}</span>
                        </a>
                        <a id="navbar-local-phone-mobile" className="sidenavbar-item" href={`tel:+15033459399`}>
                            <span>{`Local: ${localPhoneNumber}`}</span>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );
};
