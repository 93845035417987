import React, { useContext, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { Button } from '@vacasa/react-components-lib';
import './Survey.scss';
import { Form, Formik, FormikProps } from 'formik';
import SwipeableViews from 'react-swipeable-views';
import {
    ActionSegment,
    DetailedError,
    ErrorTypes,
    GeneralInformation,
    Question,
    QuestionAttributeOptions,
    QuestionType,
    SurveyResponse,
    SurveyResponseAnswers,
    SurveyType,
} from '@reviews/interfaces';
import { UnitPanel } from '../UnitPanel/UnitPanel';
import { MessagePanel } from '../MessagePanel/MessagePanel';
import { usePostSurveyResponseMutation } from '../../services';
import { Spinner } from '../../assests/icons';
import segmentService from '../../services/segment/segmentService';
import { OptimizelyContext } from '@optimizely/react-sdk';
import { Events } from '../../types/split.type';
import { useNavigate } from 'react-router-dom';
import { AppRoutes } from '../../Router';
import _ from 'lodash';

export type SurveyInfo = {
    unit: {
        name: string;
        city: string;
        state: string;
        img: string;
    };
    general_information: GeneralInformation;
};

export type SurveyWizardStep = {
    component: JSX.Element;
    title: string;
    description: string;
    validationSchema: any;
    rawQuestions: Array<Question>;
};

interface SurveyWizardProps {
    steps: Array<SurveyWizardStep>;
    surveyInfo: SurveyInfo;
    showWizardStepNavigation?: boolean;
}

export function SurveyWizard(props: SurveyWizardProps) {
    const { steps, surveyInfo } = props;
    const navigate = useNavigate();
    const { optimizely } = useContext(OptimizelyContext);
    const [currentStep, setCurrentStep] = useState(0);
    const [postSurveyResponse, { isLoading: isSubmittingResponse, data: submitResponse, error: submitError }] =
        usePostSurveyResponseMutation();

    const formRef = useRef<FormikProps<any>>(null);

    useLayoutEffect(() => {
        window.scrollTo(0, 0);
    });

    useEffect(() => {
        (async function validateForm() {
            const currentValues = formRef.current?.values;
            await formRef.current?.validateForm(currentValues);
        })();
    }, [currentStep]);

    const isLastStep = () => {
        return currentStep === steps.length - 1;
    };

    const handleSubmit = async (values: { [key: string]: any }, questions: Question[]) => {
        if (!isLastStep()) {
            handleNext();
            segmentService.trackEvent({
                action: ActionSegment.NEXT,
            });
            return;
        }
        const answers = getResponseAnswers(values, questions);
        const surveyResponse = transformToSurveyResponse(answers, surveyInfo.general_information);

        try {
            const resultSurvey = await postSurveyResponse({
                surveyID: surveyInfo.general_information.survey_id,
                response: surveyResponse,
            }).unwrap();
            segmentService.trackEvent({
                action: ActionSegment.SUBMIT,
                review_id: resultSurvey.data.attributes.review_id,
            });
            optimizely?.track(Events.REVIEWS_APP_SUBMIT_EVENT);
        } catch (_e) {
            navigate(AppRoutes.HOME, { state: { kind: ErrorTypes.UNHANDLED } });
        }
    };

    const transformToSurveyResponse = (valuesReview: any, generalInformation: GeneralInformation): SurveyResponse => {
        return {
            first_name: generalInformation.first_name,
            last_name: generalInformation.last_name,
            email: generalInformation.email,
            type: SurveyType.REVIEW,
            admin_reservation_id: generalInformation.admin_reservation_id,
            admin_unit_id: generalInformation.admin_unit_id,
            source: 'GUEST',
            survey_response_answers: valuesReview as Array<SurveyResponseAnswers>,
            survey_test_information: undefined,
        };
    };

    const handleNext = () => {
        const nextStep = Math.min(currentStep + 1, steps.length - 1);
        setCurrentStep(nextStep);
    };

    const getResponseAnswers = (values: { [key: string]: string | number | boolean }, rawQuestions: Array<Question>) => {
        const keys = _.keys(values);
        const mapAnswers: { [key: string]: any } = {};

        for (const key of keys) {
            const [questionId, _name, type, attribute] = key.split('-');
            let question_id = +questionId;
            const isDismissed = attribute === QuestionAttributeOptions.DISMISSED || mapAnswers[question_id]?.answer.dismissed;

            if (!mapAnswers[question_id]) {
                mapAnswers[question_id] = {
                    question_id,
                    type,
                    answer: {
                        dismissed: false,
                        data: {},
                    },
                };
            }
            if (isDismissed) {
                mapAnswers[question_id].answer.dismissed = isDismissed;
                mapAnswers[question_id].answer.data = null;
            } else {
                mapAnswers[question_id].answer.data = {
                    [attribute]: type === QuestionType.RATING && attribute === 'answer' ? +values[key] : values[key],
                    ...(mapAnswers[question_id].answer.data || {}),
                };
            }
        }

        // Optional questions may not have a value on the form. Add them manually
        for (const question of rawQuestions) {
            const { question_id, required, type } = question;
            if (!mapAnswers[question_id]) {
                mapAnswers[question_id] = {
                    question_id: +question_id,
                    type,
                    answer: {
                        dismissed: false,
                        data: null,
                    },
                };

                if (required) {
                    console.debug(
                        `question ${question_id} of type ${type} is required but doesn't have an answer. Validations may have failed`
                    );
                }
            }
        }

        return _.values(mapAnswers);
    };

    const activeStep = steps[currentStep];
    const validationSchema = activeStep?.validationSchema || {};

    if (isSubmittingResponse) {
        return (
            <div className="loading">
                <Spinner />
            </div>
        );
    }

    if (submitError) {
        const errorKind = (submitError as DetailedError).code || ErrorTypes.UNHANDLED;
        return <MessagePanel kind={errorKind as ErrorTypes} action={ActionSegment.SUBMIT} />;
    }

    if (submitResponse) {
        return <MessagePanel kind="THANKS" action={null} />;
    }

    const {
        general_information: { first_name, last_name },
        unit,
    } = surveyInfo;

    const allQuestions = _.reduce(
        steps,
        (acc, curr) => {
            acc.push(...curr.rawQuestions);
            return acc;
        },
        [] as Question[]
    );

    return (
        <React.Fragment>
            <div id={`feedback-B`} className={'mt-3'}>
                <Formik
                    innerRef={formRef}
                    initialValues={{}}
                    onSubmit={(values) => handleSubmit(values, allQuestions)}
                    validateOnMount={true}
                    validationSchema={validationSchema}
                >
                    {({ isValid, submitForm }) => {
                        return (
                            <React.Fragment>
                                <Form
                                    onSubmit={(e) => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                    }}
                                >
                                    <SwipeableViews index={currentStep} disabled={true}>
                                        {steps &&
                                            steps.map((_step: any, index: number) => {
                                                return currentStep === index ? (
                                                    <div className="survey-section-step" key={index}>
                                                        <h1 className={'h1 base-font-title fix-title'}>{steps[index].title}</h1>
                                                        <p className={'base-font-body sub-title-rating'}>{steps[index].description}</p>
                                                        {index === 0 && <UnitPanel {...unit} />}
                                                        {steps[index].component}
                                                        {index === 0 && (
                                                            <div className={'field-wrapper caption name-change'}>
                                                                <div className={'base-font-body category-description'}>
                                                                    {`You're giving feedback as  ${first_name} ${last_name
                                                                        .charAt(0)
                                                                        .toUpperCase()}`}
                                                                </div>
                                                            </div>
                                                        )}
                                                    </div>
                                                ) : (
                                                    <React.Fragment />
                                                );
                                            })}
                                    </SwipeableViews>
                                    {steps.length > 0 && (
                                        <div className="row field-wrapper flex-row-reverse">
                                            <div className={'col-md-6 col-md-offset-6 d-flex flex-row-reverse'}>
                                                <Button
                                                    customClass={'button body-regular'}
                                                    variant={'primary'}
                                                    disabled={!isValid}
                                                    onClick={submitForm}
                                                >
                                                    {isLastStep() ? 'Submit' : 'Next'}
                                                </Button>
                                            </div>
                                        </div>
                                    )}
                                </Form>
                            </React.Fragment>
                        );
                    }}
                </Formik>
            </div>
        </React.Fragment>
    );
}
