import React, { useLayoutEffect } from 'react';
import {
    ActionSegment,
    BaseOptions,
    CommentOptions,
    DetailedError,
    ErrorTypes,
    Question,
    QuestionAttributeOptions,
    QuestionCategory,
    QuestionType,
    RatingOptions,
    Section,
    SurveyDetails,
    SurveyName,
    SurveyType,
} from '@reviews/interfaces';
import * as _ from 'lodash';
import { Comment } from '../Comment/Comment';
import { Rating } from '../RatingScale/Rating';
import * as Yup from 'yup';
import { SurveyInfo, SurveyWizard, SurveyWizardStep } from './SurveyWizard';
import './Survey.scss';
import '../../assests/styles/shared.scss';
import { MessagePanel } from '../MessagePanel/MessagePanel';
import { useGetSurveyForReservationQuery } from '../../services';
import { Spinner } from '../../assests/icons';

interface SurveyProps {
    type: SurveyType;
    reservationId: number;
    confirmationCode: string;
    nsuHash: string;
    surveyName: SurveyName;
    commentRequired: boolean;
}

export const Survey: React.FC<SurveyProps> = (props: SurveyProps) => {
    const { type, reservationId, confirmationCode, nsuHash, surveyName, commentRequired } = props;
    const { isLoading, data, error } = useGetSurveyForReservationQuery({
        type,
        reservationId,
        confirmationCode,
        nsuHash,
        name: surveyName,
    });

    const renderQuestion = (type: QuestionType, question: Question): JSX.Element => {
        const { description, name, question_id, title, configuration, required } = question;
        const isPublicReviewQuestion = _.isEqual(name, QuestionCategory.PUBLIC_REVIEW) && _.isEqual(type, QuestionType.COMMENT);

        switch (type) {
            case 'COMMENT':
                return (
                    <Comment
                        name={`${question_id}-${name}-${type}`}
                        title={title}
                        description={description}
                        required={isPublicReviewQuestion ? commentRequired : required}
                        configuration={configuration as BaseOptions & CommentOptions}
                    />
                );
            case 'RATING':
                return (
                    <Rating
                        name={`${question_id}-${name}-${type}`}
                        title={title}
                        description={description}
                        required={required}
                        configuration={configuration as BaseOptions & RatingOptions}
                    />
                );
            default:
                return <></>;
        }
    };

    const renderQuestionsContainer = (questions: Array<Question>): JSX.Element => {
        return (
            <React.Fragment>
                {questions.map((question, index) => (
                    <div key={index} id={`question-${index}`}>
                        {renderQuestion(question.type, question)}
                    </div>
                ))}
            </React.Fragment>
        );
    };

    const getGeneralInformation = ({ survey_id, context }: SurveyDetails): SurveyInfo => ({
        unit: {
            name: context.unit.name,
            city: context.unit.city || '',
            state: context.unit.state || '',
            img: context.unit.img,
        },
        general_information: {
            survey_id: +survey_id,
            first_name: context.reservation.first_name || '',
            last_name: context.reservation.last_name || '',
            email: context.reservation.email || '',
            admin_reservation_id: context.reservation.admin_reservation_id,
            admin_unit_id: context.unit.admin_unit_id,
        },
    });

    const renderStepInfo = (section: Section): SurveyWizardStep => {
        return {
            component: renderQuestionsContainer(section.section_questions),
            title: section.title,
            description: section.description,
            validationSchema: Yup.object().shape(getValidationSchema(section.section_questions)),
            rawQuestions: section.section_questions,
        };
    };

    const getValidationSchema = (questions: Array<Question>): any => {
        let validationSchema = new Map<string, any>();
        questions.forEach((question) => {
            const fieldDismissible = `${question.question_id}-${question.name}-${question.type}-${QuestionAttributeOptions.DISMISSED}`;
            let stringSchema = question.required ? Yup.string().required() : Yup.string().optional();
            const answerKey = `${question.question_id}-${question.name}-${question.type}-${QuestionAttributeOptions.ANSWER.toLowerCase()}`;

            if (question.configuration.dismissible) {
                validationSchema.set(
                    fieldDismissible,
                    Yup.string().when(answerKey, {
                        is: undefined,
                        then: stringSchema,
                    })
                );
            }
            const isPublicReviewQuestion =
                _.isEqual(question.name, QuestionCategory.PUBLIC_REVIEW) && _.isEqual(question.type, QuestionType.COMMENT);
            if (commentRequired && isPublicReviewQuestion) {
                stringSchema = Yup.string().trim().required('required');
            }

            validationSchema.set(answerKey, question.configuration.dismissible ? Yup.string().optional() : stringSchema);
        });

        return Array.from(validationSchema.entries()).reduce((main, [key, value]) => ({ ...main, [key]: value }), {});
    };

    const getStepsInfo = (sections: Array<Section>): Array<SurveyWizardStep> => {
        if (!sections) {
            return [];
        }
        return sections.map((step) => renderStepInfo(step));
    };

    useLayoutEffect(() => {
        window.scrollTo(0, 0);
    });

    if (isLoading) {
        return (
            <div className="loading">
                <Spinner />
            </div>
        );
    }

    if (error) {
        const kind = (error as DetailedError)?.code || ErrorTypes.UNHANDLED;
        return <MessagePanel kind={kind as ErrorTypes} action={ActionSegment.PAGE_LOAD} />;
    }

    if (!data || _.isEmpty(data.data)) {
        return null;
    }

    const { attributes } = data.data;
    const generalInformation = getGeneralInformation(attributes);
    const steps = getStepsInfo(attributes.sections);

    return <SurveyWizard surveyInfo={generalInformation} steps={steps} />;
};
