import { configureStore } from '@reduxjs/toolkit';
import { surveysApi } from '../services';
import { rtkQueryErrorFormatter } from './error.middleware';

export const store = configureStore({
    reducer: {
        [surveysApi.reducerPath]: surveysApi.reducer,
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(rtkQueryErrorFormatter),
});

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;
