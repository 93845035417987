import horizontalLogo from '../../../assests/images/vacasa-logo-horizontal-blue.svg';
import { IconMenuTwo, IconXTwo } from '../../../assests/icons';
import { RightNavBarMenu } from '../RightNavBarMenu/RightNavBarMenu';
import { LeftMobileNavMenu } from '../LeftMobileNavMenu/LeftMobileNavMenu';
import { Configuration } from '../../../Configuration';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import './NavBar.scss';

export const NavBar = () => {
    const logoURL = Configuration.getVacasaUrl();
    const tollFreePhoneNumber = Configuration.getTollFreePhoneNumber();
    const localPhoneNumber = Configuration.getLocalPhoneNumber();

    const handleLogoClick = () => {
        window.location.assign(logoURL);
    };

    const handleLeftMenuClick = () => {
        const navbarUser = document.getElementById('navbar-user');
        const navbar = document.getElementById('navbar-main');
        const navPanel = document.getElementById('sidenavbar-left');
        const iconMenu = document.getElementById('left-icon-menu');
        const iconClose = document.getElementById('left-icon-close');

        if (!navbarUser || !navbar || !navPanel || !iconMenu || !iconClose) {
            return;
        }

        navbarUser.classList.toggle('fixed-top');
        navPanel.classList.toggle('d-none');
        navPanel.classList.toggle('left-inactive');
        iconMenu.classList.toggle('inactive');
        iconClose.classList.toggle('inactive');
        document.body.classList.toggle('no-scroll');
        navbar.classList.toggle('mb-5');

        if (navPanel.classList.contains('left-inactive')) {
            enableBodyScroll(navPanel);
        } else {
            disableBodyScroll(navPanel);
        }
    };

    return (
        <div id="navbar-user">
            <LeftMobileNavMenu localPhoneNumber={localPhoneNumber} tollFreePhoneNumber={tollFreePhoneNumber} />
            <nav id="navbar-main" className="navbar navbar-expand-lg  navbar-light bg-white container fix-pad">
                <div className="hamburger-menu-wrapper">
                    <span className="navbar-toggler sidenavbar-left-toggler border-0">
                        <IconMenuTwo id="left-icon-menu" className="feather icon-menu pointer" onClick={handleLeftMenuClick} />
                        <IconXTwo
                            id="left-icon-close"
                            className="feather inactive icon-menu light-stroke pointer"
                            onClick={handleLeftMenuClick}
                        />
                    </span>
                </div>
                <a id="navbar-vacasa-logo" className="navbar-brand" onClick={handleLogoClick}>
                    <span>
                        <img className="d-lg-none horizontal-logo" src={horizontalLogo} alt="Vacasa" />
                        <img className="d-none d-lg-block horizontal-logo" src={horizontalLogo} alt="Vacasa" />
                    </span>
                </a>
                <span className="navbar-toggler sidenavbar-right-toggler border-0">
                    <IconXTwo id="right-icon-close" className="icon-fake" />
                </span>
                <div className="collapse navbar-collapse">
                    <RightNavBarMenu localPhoneNumber={localPhoneNumber} tollFreePhoneNumber={tollFreePhoneNumber} />
                </div>
            </nav>
        </div>
    );
};
