import { isRejectedWithValue } from '@reduxjs/toolkit';
import type { MiddlewareAPI, Middleware } from '@reduxjs/toolkit';
import { DetailedError } from '@reviews/interfaces';
import { Configuration } from '../Configuration';
import defaultLogger from '@reviews/frontend/build/src/logging/ddLogger';

export const rtkQueryErrorFormatter: Middleware = (_api: MiddlewareAPI) => (next) => (action) => {
    if (isRejectedWithValue(action)) {
        const {
            arg: { endpointName, originalArgs },
        } = action.meta;
        const error = action.payload || action.error;
        action.payload = makeDetailedError(endpointName, error, originalArgs);
    }

    return next(action);
};

const makeDetailedError = (tag: string, error: any, _args: object): DetailedError => {
    let detail: string = error?.message || 'UNKNOWN';
    let status = 0;
    let code: string | number = error.code || status;
    const stack = error.stack || [];

    if (Array.isArray(error.data)) {
        const [errorData] = error.data;
        const { title, type, status: statusCode } = errorData.detail?.error;
        detail = title;
        code = type;
        status = statusCode;
    }

    const message = `${tag} - STATUS ${status}, CODE: ${code} - ${detail}`;
    const detailed = { name: tag, message, status, stack, code, app_version: Configuration.getAppVersion() };
    defaultLogger.error(message, { data: { detail: detailed } });
    return detailed;
};
