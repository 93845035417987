import {
    MaintenanceTicket,
    SurveyHistory,
    SurveyResponseAnswers,
    BaseUser,
    UserResponse,
    UnitData,
    GetSurveyResponseAnswerResponse,
    MaintenanceTicketResponse,
    SurveyHistoryResponse,
    Reservation,
    CategoryName,
    ReviewAutoProcessSummary,
} from '../index';
import { SurveyResponseResponse } from './survey-response.type';
import { JsonApiMetaData } from '@vacasa/json-api-common';
import { UnitResponse } from './unit.type';
import { JsonApiArrayResponse, JsonApiBody } from './json-api.type';

export interface ReviewOverviewResponse {
    type: 'review';
    attributes: BaseReview;
}

export interface ReviewDetailResponse {
    type: 'review-detail';
    attributes: DetailedReview;
}

export interface ReviewGetResponse {
    type: 'review';
    id: number;
    attributes: BaseReviewResponse;
}

export interface UpdateUnitRatingResponse {
    type: 'update-unit-rating';
    attributes: {
        unit_id: number;
    };
}

export interface NinetyDayAvgResponse {
    type: '90-day-avg';
    attributes: NinetyDayAvg;
}

// GET#/v1/reviews/response
export interface GetReviewsResponse {
    data: Array<SurveyResponseResponse>;
    included?: Array<
        | UnitResponse
        | ReviewGetResponse
        | UserResponse
        | GetSurveyResponseAnswerResponse
        | MaintenanceTicketResponse
        | SurveyHistoryResponse
        | (UnitResponse & ReviewGetResponse)
        | (UnitResponse & ReviewGetResponse & UserResponse)
        | (UnitResponse & ReviewGetResponse & UserResponse & GetSurveyResponseAnswerResponse)
        | (UnitResponse & ReviewGetResponse & UserResponse & GetSurveyResponseAnswerResponse & MaintenanceTicketResponse)
        | (UnitResponse &
              ReviewGetResponse &
              UserResponse &
              GetSurveyResponseAnswerResponse &
              MaintenanceTicketResponse &
              SurveyHistoryResponse)
    >;
    meta?: JsonApiMetaData;
}

export type GetReviewTicketsResponse = JsonApiArrayResponse<'maintenance-ticket-review', MaintenanceTicketReview>;

export type ReviewTicketResponse = JsonApiBody<'maintenance-ticket-review', MaintenanceTicketReview>;
/***********************************/

export enum ReviewStatus {
    CREATING = 'CREATING',
    NEW = 'NEW',
    PENDING = 'PENDING',
    SUPPRESSED = 'SUPPRESSED',
    PUBLISHED = 'PUBLISHED',
    RESPONSE_REQUIRED = 'RESPONSE_REQUIRED',
    UNKNOWN = 'UNKNOWN',
    ANSWERED = 'ANSWERED',
}

export enum ReviewRole {
    GUEST = 'guest',
    HOST = 'host',
}

export const mapReviewStatusToString: { [key: string]: string } = {
    [ReviewStatus.NEW]: 'New',
    [ReviewStatus.PENDING]: 'Pending',
    [ReviewStatus.SUPPRESSED]: 'Suppressed',
    [ReviewStatus.PUBLISHED]: 'Published',
    [ReviewStatus.RESPONSE_REQUIRED]: 'Response Required',
    [ReviewStatus.UNKNOWN]: 'Unknown',
};

export interface MaintenanceTicketReview {
    review_id: number;
    review_date: Date;
    unit_code: string | undefined;
    reservation_id: number | undefined;
    assigned_to: BaseUser | null;
    unit_data: UnitData | undefined;
    admin_ticket_id: number;
    ticket_id: number;
    ticket_update_at: Date;
    ticket_created_at: Date;
}

export interface BaseReviewResponse {
    review_id: number;
    survey_response_id: string;
    display_review: boolean;
    display_rating: boolean;
    response: string | null;
    rating: number;
    review_date: string;
    status: ReviewStatus;
    display_review_notes: string | null;
    display_rating_notes: string | null;
}

export interface BaseReview {
    review_id: number;
    survey_response_id: number;
    display_review: boolean;
    display_review_notes: string | null;
    display_rating: boolean;
    display_rating_notes: string | null;
    response?: string | null;
    rating: number;
    review_date: Date;
    status: ReviewStatus;
    unit_code?: string | null;
    unit_name?: string | null;
    admin_unit_id: number;
}

export interface DetailedReview extends BaseReview {
    unit_id: number;
    admin_reservation_id: number | null;
    first_name: string;
    last_name: string;
    email: string;
    processed: false;
    processed_by: BaseUser | null;
    assigned_to: BaseUser | null;
    assigned_at: string;
    survey_history: Array<SurveyHistory>;
    maintenance_tickets: Array<MaintenanceTicket>;
    reservation: Reservation;
    answers: Array<SurveyResponseAnswers>;
    unit_data: UnitData | undefined;
    review_summary_data: ReviewAutoProcessSummary | null;
}

export interface BaseConnectReview {
    unit_id: number;
    reservation_id: number;
    review_date: Date;
    updated_at: Date;
    first_name: string;
    last_initial: string;
    display_rating: boolean;
    display_review: boolean;
    response: string;
    star_rating: number;
    submission_status: number;
    review_id: number;
    overall: number;
}

export interface ConnectReview extends BaseConnectReview {
    city: string;
    state: string;
    heading: string;
    review: string;
    cleanliness: number;
    location: number;
    location_city: number;
    property_condition: number;
    service: number;
    recommend: number;
}

export interface VRBOReview {
    review_id: number;
    updated_at: Date;
    unit_id: number;
    review_url: string;
    advertiser: string;
}

export interface VRBOReviewDetail {
    review_id: number;
    review_date: Date;
    updated_at: Date;
    first_night: Date;
    review: string;
    booking_external_id: string;
    review_url: string;
    review_heading: string;
    review_response: string;
    rating: number;
    first_name: string;
    last_name: string;
    email: string;
    unit_id: number;
}

export interface MonolithReview {
    reviewid: number;
    reservationid: number | null;
    reservation_source_id: number | null;
    unitid: number | null;
    reviewdate: string | null; // "2017-07-12",
    modifieddatetime: string; // "2017-07-12 16:27:51",
    submission_status: number | null;
    firstname: string | null;
    lastname: string | null;
    email: string | null;
    city: string | null;
    state: string | null;
    heading: string | null;
    review: string | null;
    thumb_rating: number | null;
    star_rating: number | null;
    overall: number | null;
    cleanliness: number | null;
    location: number | null;
    locationcity: number | null;
    locationcomments: string | null;
    wifi_rating: number | null;
    wifi_comment: string | null;
    test_rating: number | null;
    test_comment: string | null;
    propertycondition: number | null;
    service: number | null;
    recommend: number | null;
    recommend_rating: number | null;
    displayrating: number | null;
    displayreview: number | null;
    response: string | null;
    cleancomments: string | null;
    conditioncomments: string | null;
    helpfulcomments: string | null;
    generalcomments: string | null;
    discussion_topic: string | null;
    vrboemail: string | null; // "2014-08-28 09:30:02"
    processed: number;
    processedby: number;
    processedat: string | null; // "2017-07-12 16:27:51",
    displaylocation: number | null;
    hidereview: string | null;
    hiderating: string | null;
    managerqueue: number;
    skippedby: number;
    skipnotes: string | null;
    contact_needed: number | null;
    contact_method: number | null;
    interacted_with_staff: number | null;
    internal_email_sent: string | null; // "2020-01-14 06:41:09"
    created_at: string; // "2019-02-26 17:05:04"
    ts: string; // "2022-06-14 06:00:00.000000",
    processed_by_email: string;
    processed_by_name: string;
}

export interface Review {
    review_id: string;
    reservation_id: number;
    channel: string;
    unit_id: number;
    first_name: string;
    last_name: string;
    email: string;
    rating: number;
    review: { title: string; comment: string };
    is_process: boolean;
    display_rating: boolean;
    display_review: boolean;
    assigned_to: string | null;

    feedback_questions: Array<{
        category: string;
        question: string;
        rating: number | null;
        comment: string | null;
    }>;

    created_at: string;
    updated_at: string;

    response?: {
        author: string;
        message: string;
        response_date: string;
    };
    maintenance_tickets: Array<{
        ticket_id: string;
        title: string;
        is_complete: boolean;
    }>;
}

export interface NinetyDayAvg {
    avg_overall: {
        name: CategoryName.OVERALL;
        avg: number;
    };
    avg_cleanliness: {
        name: CategoryName.CLEANLINESS;
        avg: number;
    };
    avg_location: {
        name: CategoryName.LOCATION;
        avg: number;
    };
    avg_property: {
        name: CategoryName.PROPERTY;
        avg: number;
    };
    avg_staff: {
        name: CategoryName.STAFF;
        avg: number;
    };
    avg_wifi: {
        name: CategoryName.WIFI;
        avg: number;
    };
    avg_nps: {
        name: CategoryName.NPS;
        avg: number;
    };
}

export type ReviewDisplayNotes = { rating?: string; review?: string };

export type PublishReviewOptions = { displayReview: boolean; notes: ReviewDisplayNotes };
