import { Route, RouteProps, Routes, BrowserRouter } from 'react-router-dom';
import { Home, SurveyView } from './views';

export enum AppRoutes {
    HOME = '/',
    SURVEY = '/review',
}

const routes: RouteProps[] = [
    { path: AppRoutes.HOME, element: <Home /> },
    { path: AppRoutes.SURVEY, element: <SurveyView /> },
    { path: '*', element: <Home /> },
];

export const Router = () => (
    <BrowserRouter>
        <Routes>
            {routes.map((route, i) => (
                <Route key={i} {...route} />
            ))}
        </Routes>
    </BrowserRouter>
);
