import React from 'react';
import './Footer.scss';
import moment from 'moment';

export const Footer: React.FC = () => {
    const currentYear = moment().year();

    return (
        <footer className="container no-print">
            <div id={'footer'} className="footer-content">
                <span className="copyright">© {currentYear} Vacasa LLC</span>
                <span className="service-links">
                    <a target="_blank" rel="noopener noreferrer" id="link-footer-sitemap" href="https://www.vacasa.com/sitemap">
                        {'Sitemap'}
                    </a>{' '}
                    |
                    <a target="_blank" rel="noopener noreferrer" id="link-footer-privacy" href="https://www.vacasa.com/policies">
                        {' '}
                        {'Privacy'}
                    </a>{' '}
                    |
                    <a target="_blank" rel="noopener noreferrer" id="link-footer-terms" href="https://www.vacasa.com/terms-of-service">
                        {' '}
                        {'Terms of Service'}
                    </a>{' '}
                    |
                    <a target="_blank" rel="noopener noreferrer" id="link-footer-accessibility" href="https://www.vacasa.com/accessibility">
                        {' '}
                        {'Accessibility'}
                    </a>
                </span>
            </div>
        </footer>
    );
};
