import { ActionSegment, ErrorSegment, ErrorTypes } from '@reviews/interfaces';
import { Button } from '@vacasa/react-components-lib';
import { ImageMaintenance } from '../../assests/images';
import { MessageUtils } from '../../assests/strings/messages';
import './MessagePanel.scss';
import segmentService from '../../services/segment/segmentService';
import { useContext, useEffect, useRef } from 'react';
import { OptimizelyContext } from '@optimizely/react-sdk';
import * as _ from 'lodash';
import { Events } from '../../types/split.type';

interface MessagePanelProps {
    kind: ErrorTypes | 'THANKS' | null;
    action: ActionSegment | null;
}

export function MessagePanel(props: MessagePanelProps): JSX.Element {
    const { optimizely } = useContext(OptimizelyContext);
    const isEventSend = useRef<boolean>(false);
    const tracked = useRef<boolean>(false);
    const { kind, action } = props;

    useEffect(() => {
        if (!_.isNil(optimizely) && !isEventSend.current && !_.isEqual(kind, 'THANKS')) {
            optimizely.track(Events.REVIEWS_APP_SHOW_ERROR_EVENT);
            isEventSend.current = true;
        }
    }, [kind, optimizely]);

    const footer = document.getElementById('footer');
    if (footer) {
        footer.classList.add('fix-bottom');
    }

    const localTracking = (error: ErrorSegment) => {
        if (tracked.current) {
            segmentService.trackEvent({
                action,
                error,
            });
            tracked.current = true;
        }
    };

    const getContentMessage = (
        kind: ErrorTypes | 'THANKS' | null
    ): {
        title?: string;
        description?: JSX.Element;
        image?: JSX.Element;
        cta?: JSX.Element;
    } => {
        const contactEmail = process.env['REACT_APP_REVIEWS_CONTACT_EMAIL'];
        const goHomeLink = process.env['REACT_APP_GO_HOME_LINK'];
        const ctaGoHome: JSX.Element = (
            <>
                <a href={goHomeLink}>
                    <Button customClass={'body-regular'} variant="primary">
                        {MessageUtils['button_lets_go_somewhere']}
                    </Button>
                </a>
            </>
        );
        switch (kind) {
            case ErrorTypes.REVIEW_EXPIRED:
                localTracking(ErrorSegment.EXPIRED);
                return {
                    title: MessageUtils['title_review_expired'],
                    description: (
                        <>
                            <p>
                                <span>{MessageUtils['description_review_expired']}</span>
                                <a title={`${contactEmail}`} href={`mailto:${contactEmail}`}>
                                    {contactEmail}
                                </a>
                            </p>
                        </>
                    ),
                    cta: ctaGoHome,
                };
            case ErrorTypes.RESERVATION_NOT_END:
                localTracking(ErrorSegment.PRIOR_DATE);
                return {
                    title: MessageUtils['title_reservation_not_end'],
                    description: (
                        <>
                            <p>
                                <span>{MessageUtils['description_reservation_not_end']}</span>
                            </p>
                            <div className={'mt-1'} />
                        </>
                    ),
                    cta: ctaGoHome,
                };
            case ErrorTypes.RESERVATION_ALREADY_ANSWERED:
                localTracking(ErrorSegment.ALREADY_EXISTS);
                return {
                    title: MessageUtils['title_reservation_already_answered'],
                    description: (
                        <>
                            <p>
                                <span>{MessageUtils['description_reservation_already_answered']}</span>
                            </p>
                            <p>
                                <span>{MessageUtils['description_reservation_already_answered_note']}</span>
                            </p>
                            <div className={'mt-1'} />
                        </>
                    ),
                    cta: ctaGoHome,
                };
            case ErrorTypes.MAINTENANCE:
                return {
                    title: MessageUtils['title_page_under_maintenance'],
                    description: (
                        <>
                            <p>
                                <span>{MessageUtils['description_page_under_maintenance']}</span>
                            </p>
                        </>
                    ),
                    image: (
                        <>
                            <ImageMaintenance className="maintenance-image" />
                            <div className={'mt-5'} />
                        </>
                    ),
                    cta: ctaGoHome,
                };
            case 'THANKS':
                return {
                    title: MessageUtils['title_thanks'],
                    description: (
                        <>
                            <p>
                                <span>{MessageUtils['description_thanks']}</span>
                            </p>
                        </>
                    ),
                    cta: ctaGoHome,
                };
            case ErrorTypes.NOT_FOUND:
                localTracking(ErrorSegment.INVALID_PARAMS);
                return {
                    title: MessageUtils['title_invalid_params'],
                    description: (
                        <>
                            <p>
                                <span>{MessageUtils['description_invalid_params']}</span>
                                <a title={`${contactEmail}`} href={`mailto:${contactEmail}`}>
                                    {contactEmail}
                                </a>
                            </p>
                        </>
                    ),
                    cta: ctaGoHome,
                };
            default:
                return {
                    title: MessageUtils['title_generic_error'],
                    description: (
                        <>
                            <p>
                                <span>{MessageUtils['description_generic_error']}</span>
                                <a title={`${contactEmail}`} href={`mailto:${contactEmail}`}>
                                    {contactEmail}
                                </a>
                            </p>
                        </>
                    ),
                    cta: ctaGoHome,
                };
        }
    };

    const valuesToRender = getContentMessage(kind);
    return (
        <div className={'info mt-5'}>
            <div id={`feedback-B`}>
                <h1 className={'base-font-title'}>{valuesToRender.title}</h1>
                <h4 className={'base-font-body sub-title'}>{valuesToRender.description}</h4>
                {valuesToRender.image}
                {valuesToRender.cta}
            </div>
        </div>
    );
}
