/*******************************/

export enum CategoryName {
    OVERALL = 'OVERALL',
    CLEANLINESS = 'CLEANLINESS',
    LOCATION = 'LOCATION',
    PROPERTY = 'PROPERTY',
    STAFF = 'STAFF',
    WIFI = 'WIFI',
    NPS = 'NPS',
}
