export enum StorageKeys {
    ACCESS_TOKEN = 'accessToken',
    ID_TOKEN = 'idToken',
    RESERVATION_ID = 'reservation_id',
    CONFIRMATION_CODE = 'confirmation_code',
    NSU = 'nsu',
    VARIANT = 'variant',
    REVIEWS_ANONYMOUS_ID = 'reviews_anonymous_id',
    DISPOSITION = 'disposition',
    ANONYMOUS_ID = 'ajs_anonymous_id',
    STAR_RATING = 'star_rating',
}

export interface Paginated<T> {
    count: number;
    items: T[];
}

export interface IdpUser {
    email: string;
    idp: boolean;
}

export interface IdentityToken {
    at_hash: string;
    aud: Array<any>;
    email: string;
    email_obscured: boolean;
    email_verified: boolean;
    exp: number;
    family_name: string;
    given_name: string;
    iat: number;
    iss: string;
    locale: string;
    login_method: string;
    name: string;
    nonce: string;
    sub: string;
    zoneinfo: string;
}
