import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { Configuration } from '../Configuration';
import { SurveyName, SurveyQuestionsResponse, SurveyResponse, SurveyType } from '@reviews/interfaces';
import { flowId, StorageKeys } from '@reviews/frontend';
import { GuestSurveyResponse } from '@reviews/interfaces';

export type ApiRequest = { pageNumber?: number; pageSize?: number; filters?: {} };

export const surveysApi = createApi({
    reducerPath: 'surveysApi',
    baseQuery: fetchBaseQuery({
        baseUrl: `${Configuration.getReviewsAPIUrl()}/v1`,
        prepareHeaders: (headers) => {
            headers.set('Content-Type', 'application/json');
            headers.set('Authorization', `Bearer ${localStorage.getItem(StorageKeys.ACCESS_TOKEN)}`);
            headers.set('x-identity-token', `${localStorage.getItem(StorageKeys.ID_TOKEN)}`);
            headers.set('X-Flow-Id', flowId);
            headers.set('X-Client-Identifier', 'reviews-guest-app');
            return headers;
        },
    }),
    endpoints: (builder) => ({
        getSurveyForReservation: builder.query<
            { data: SurveyQuestionsResponse },
            { type: SurveyType; reservationId: number; confirmationCode: string; nsuHash: string; name: SurveyName }
        >({
            query: (req) => {
                const params = {
                    'filter[reservation_id]': req.reservationId,
                    'filter[confirmation]': req.confirmationCode,
                    'filter[nsu]': req.nsuHash,
                    'filter[name]': req.name,
                };
                return {
                    url: `/surveys/${req.type}`,
                    method: 'GET',
                    params,
                };
            },
        }),

        postSurveyResponse: builder.mutation<{ data: GuestSurveyResponse }, { surveyID: number; response: SurveyResponse }>({
            query: ({ surveyID, response }) => ({
                url: `/surveys/${surveyID}`,
                method: 'POST',
                body: {
                    data: {
                        type: 'survey-response',
                        attributes: response,
                    },
                },
            }),
        }),
    }),
});

export const { useGetSurveyForReservationQuery, usePostSurveyResponseMutation } = surveysApi;
