import { FrontendUtils, StorageKeys } from '@reviews/frontend';
import defaultLogger from '@reviews/frontend/build/src/logging/ddLogger';

class SegmentService {
    private reservationId: string | null = FrontendUtils.SharedUtils.getSessionStorageItem(StorageKeys.RESERVATION_ID);
    private confirmationCode: string | null = FrontendUtils.SharedUtils.getSessionStorageItem(StorageKeys.CONFIRMATION_CODE);
    private nsuHash: string | null = FrontendUtils.SharedUtils.getSessionStorageItem(StorageKeys.NSU);
    private experiment: string | null = FrontendUtils.SharedUtils.getSessionStorageItem(StorageKeys.VARIANT);
    private disposition: string | null = FrontendUtils.SharedUtils.getSessionStorageItem(StorageKeys.DISPOSITION);
    private starRating: string | null = FrontendUtils.SharedUtils.getSessionStorageItem(StorageKeys.STAR_RATING);

    public trackEvent(data: { [key: string]: any }, title = 'Guest Reviews'): void {
        try {
            if (window.analytics) {
                const options = {
                    category: 'guest',
                    page_version: 'reviews_app',
                    reservation_id: this.reservationId,
                    confirmation_code: this.confirmationCode,
                    nsu: this.nsuHash,
                    variant: this.experiment,
                    disposition: this.disposition,
                    star_rating: this.starRating,
                    ...data,
                };
                window.analytics.track(title, options);
            }
            defaultLogger.info(`segment trackEvent ${data?.action}`, {
                reservation_id: this.reservationId,
                confirmation_code: this.confirmationCode,
                nsu: this.nsuHash,
                variant: this.experiment,
                disposition: this.disposition,
                star_rating: this.starRating,
                ...data,
            });
        } catch (e) {
            defaultLogger.error('segment trackEvent Error', {
                reservation_id: this.reservationId,
                confirmation_code: this.confirmationCode,
                nsu: this.nsuHash,
                variant: this.experiment,
                disposition: this.disposition,
                star_rating: this.starRating,
                ...data,
                error: { kind: e.message },
            });
        }
    }

    public trackPage(): void {
        try {
            if (window.analytics) {
                window.analytics.page('Guest Reviews Viewed', { reservation_id: this.reservationId });
            }
            defaultLogger.info('segment trackPage', {
                reservation_id: this.reservationId,
                confirmation_code: this.confirmationCode,
                nsu: this.nsuHash,
                variant: this.experiment,
                disposition: this.disposition,
                star_rating: this.starRating,
            });
        } catch (e) {
            defaultLogger.error('segment trackPageView Error', {
                reservation_id: this.reservationId,
                confirmation_code: this.confirmationCode,
                nsu: this.nsuHash,
                variant: this.experiment,
                disposition: this.disposition,
                star_rating: this.starRating,
                error: { kind: e.message },
            });
        }
    }

    public userAnalyticExist() {
        try {
            return window.analytics && window.analytics.user();
        } catch (_e) {
            return false;
        }
    }
}

export default new SegmentService();
