import './Comment.scss';
import '../Survey/Survey.scss';
import { BaseOptions, CommentOptions, QuestionAttributeOptions } from '@reviews/interfaces';
import { Field } from 'formik';

interface CommentProps {
    name: string;
    title: string | null;
    description: string | null;
    required: boolean;
    configuration: BaseOptions & CommentOptions;
}

export function Comment(props: CommentProps) {
    const { name, title, description, configuration, required } = props;

    return (
        <div className={'question'}>
            <div className={'field-wrapper base-font-body category-name'}>
                {title}
                <div className={'caption category-description'}>{description}</div>
            </div>
            {configuration.options.title && (
                <div className="field-wrapper">
                    <div className={'mt-1'} />
                    <Field
                        className={'form-control text-area-border'}
                        name={`${name}-${QuestionAttributeOptions.TITLE.toLowerCase()}`}
                        id={`${name}-${QuestionAttributeOptions.TITLE.toLowerCase()}`}
                        type="text"
                        maxLength={configuration.options.title.options.max_length}
                        placeholder={configuration.options.title.options.placeholder}
                    />
                </div>
            )}
            <div className="field-wrapper" style={required ? { paddingBottom: '15px' } : {}}>
                <Field
                    as={'textarea'}
                    className={'form-control text-area-border'}
                    name={`${name}-${QuestionAttributeOptions.ANSWER.toLowerCase()}`}
                    id={`${name}-${QuestionAttributeOptions.ANSWER.toLowerCase()}`}
                    maxLength={configuration.options.max_length}
                    placeholder={configuration.options.placeholder}
                />
                {required && <label className={'required-label'}>Required</label>}
            </div>
        </div>
    );
}
